import axios from 'axios'
import router from '@/router/index.js';
import store from '@/store';

import { Message } from 'element-ui'
import { message } from '@/utils/reset-message'

const service = axios.create({
    baseURL: 'https://api.cnc-x.cn',
    // baseURL: 'http://192.168.1.100:8888',
    timeout: 30000 // 超时时间
})
// 这些接口需表单类型
let configUrl = [
    '/mall/mall-purchase-demand/save',
    '/mall/mall-cart/del',
    '/collector/mall_browse_history/save',
    '/mall/mall-store/enter',
    '/api/user/addx',
    '/mall/mall-evaluate/save',
    '/mall/kdniao/getInfo',
    '/mall/mall-order-info/cancelOrder',
    '/mall/mall-user-collect/save',
    '/mall/mall-user-collect/del',
    '/user/alipay/VR/wxQueryOrder',
    '/user/alipay/repairPay',
    '/user/user-address/addx',
    '/mall/mall-order-info/searchOrder',
]
// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在请求头中添加 token 等信息 
        // config.headers['CusAuthorization'] = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxNjM2MzEyNzc1OTAwOTI1OTUyIiwibWFpbnRhaW5WZXIiOm51bGwsIm9wZW5JZCI6bnVsbCwic2Vzc2lvbmtleSI6bnVsbCwicGF5ZWVUeXBlIjoiMSIsInVzZXJOYW1lIjoiSlNLWF81OTAwOTI1OTUyIiwidHlwZSI6bnVsbCwidXNlcklkIjoiMTYzNjMxMjc3NTkwMDkyNTk1MiIsIm1pbmlTdG9yZUlkIjoiMCIsImFwcFR5cGUiOiIzIiwiYXBwSWQiOiJ3eDVjNTFlZDYwMzMyMDQ1ZGQiLCJ1c2VyTWFpbnRhaW5WZXIiOm51bGwsImV4cCI6MTY4NTk2OTI5NSwibWFsbFZlciI6bnVsbH0.s_EuH_XhN9T3etPnb7wN2qdJ27fYDDwjHCWM6d6q10k'
        // config.headers['CusAuthorization'] = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxNjE1MjMyOTY1NTE0NzYwMTkyIiwibWFpbnRhaW5WZXIiOm51bGwsIm9wZW5JZCI6Im9JeDZINVNUbkh0UFdrVUxFTGtsbjVoSFdMOEEiLCJzZXNzaW9ua2V5IjoiZDNaTEsvT1QwM01HV3FuUmpLVW1WQT09IiwicGF5ZWVUeXBlIjoiMSIsInVzZXJOYW1lIjoi5rWZ5rGf5bCP5YiYIiwidHlwZSI6bnVsbCwidXNlcklkIjoiMTYxNTIzMjk2NTUxNDc2MDE5MiIsIm1pbmlTdG9yZUlkIjoiMCIsImFwcFR5cGUiOiIzIiwiYXBwSWQiOiJ3eDVjNTFlZDYwMzMyMDQ1ZGQiLCJ1c2VyTWFpbnRhaW5WZXIiOm51bGwsImV4cCI6MTY5MDk1NzQwNSwibWFsbFZlciI6bnVsbH0.IHJMcyrEEjHnuBJ1fWLHQjctx00GLoioPRcx4hME_Vo'
        config.headers['CusAuthorization'] = localStorage.getItem('token')
        if (configUrl.includes(config.url)) {
            config.headers['Content-Type'] = 'multipart/form-data'
        } else {
            config.headers['Content-Type'] = 'application/json'
        }
        config.headers['App-Id'] = store.state.openId
        return config
    },
    error => {
        // console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 判断响应状态码是否为 200
        if (response.status == 200) {
            // 对响应数据进行处理


            // 认证过期 重新登录 
            if (response.data.statusCode == 8100) {
                console.log(router.currentRoute)
                if (router.currentRoute.path !== '/login') {
                    localStorage.setItem('historyRoute', router.currentRoute.fullPath);
                    // Message({
                    //     message: '登录过期,请重新登录',
                    //     type: 'error',
                    //     duration: 2 * 1000
                    // })
                    // message({
                    //     message: '登录过期,请重新登录',
                    //     type: 'error',
                    //     duration: 2 * 1000
                    // })
                    router.push('/login')
                    // setTimeout(() => {
                    // }, 2000);
                }

            } else {
                if (response.data.message) {
                    message({
                        message: response.data.message,
                        type: 'error',
                        duration: 2 * 1000
                    })
                }

            }

            return response.data
        } else {

            // 响应状态码不是 200，抛出错误
            throw new Error('请求错误')
        }
    },
    error => {
        // 处理请求错误
        console.error(error)
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        message({
            message: error.message,
            type: 'error',
            duration: 2 * 1000
        })
        throw error

    }
)


// service.interceptors.response.use(
//     response => {
//       const res = response

//       // 如果返回的状态码不是 200，说明接口有问题
//       if (res.code !== 200) {
//         Message({
//           message: res.msg || 'Error',
//           type: 'error',
//           duration: 5 * 1000
//         })

//         // 401:未登录; 403:登录过期;
//         if (res.code === 401 || res.code === 403) {
//           MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
//             confirmButtonText: 'Re-Login',
//             cancelButtonText: 'Cancel',
//             type: 'warning'
//           }).then(() => {
//             // 清除 token 信息，跳转到登录页面
//             // to re-login
//             // router.push('/login')
//           })
//         }
//         return Promise.reject(new Error(res.msg || 'Error'))
//       } else {
//         return res
//       }
//     },
//     error => {
//       console.log('err' + error) // for debug
//       Message({
//         message: error.message,
//         type: 'error',
//         duration: 5 * 1000
//       })
//       return Promise.reject(error)
//     }
//   )


export default service