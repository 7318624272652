<template>
  <div class="content">
    <div class="top">
      <div class="among">
        <div class="userInfo">
          <div style="margin-right: 20px" @click="goHome">商城首页</div>
          <div style="margin-right: 4px" v-if="$store.state.userInfo.userName">{{ $store.state.userInfo.userName }}</div>
          <span style="margin-right: 20px">你好,欢迎光临cnc-x商城</span>
          <div style="margin-right: 20px" @click="shoppingCart">购物车</div>
          <div style="margin-right: 20px" @click="myOrder">我的订单</div>
          <div style="margin-right: 20px" @click="myCollect">收藏夹</div>
          <div style="margin-right: 20px" @click="helpCenter">帮助中心</div>
          <div style="margin-right: 20px" @click="logIn" v-if="!$store.state.userInfo.userId">登录</div>
          <div style="margin-right: 20px" @click="logOut" v-else>退出</div>
        </div>
      </div>
    </div>

    <div class="bot">
      <div class="among">
        <div class="box">
          <div class="l" @click="shouYe">
            <img src="@/assets/logoSC.png" alt="出错了" />
          </div>
          <div class="z">
            <div class="inp_content">
              <div class="inp">
                <input type="text" v-model="inpVal" placeholder="请输入搜索内容" @keyup.enter="handleEnterKey" />
                <div class="btn" @click="sousuo">搜索</div>
              </div>
              <div class="text">
                <!-- <span class="sousuoKey" @click="sousKeyClick(i)" v-for="(i, index) in keyVallueSous" :key="index">{{ i
                }}</span> -->
                <span class="sousuoKey" @click="sousKeyClick('立式加工中心', '010101')">立式加工中心</span>
                <span class="sousuoKey" @click="sousKeyClick('卧式加工中心', '010102')">卧式加工中心</span>
                <span class="sousuoKey" @click="sousKeyClick('立式车床', '010201')">立式车床</span>
                <span class="sousuoKey" @click="sousKeyClick('液压剪板机', '020102')">液压剪板机</span>
                <span class="sousuoKey" @click="sousKeyClick('光纤激光切割机', '030101')">光纤激光切割机</span>
              </div>
            </div>
            <div class="gwc" @click="shoppingCart">
              <img src="@/assets/icon/gwc.png" alt="出错了" />
              <div class="text">我的购物车</div>
              <div class="tab" v-if="totalLength">{{ totalLength }}</div>
            </div>
          </div>
          <div class="r" style="background-color: #fff;">
            <div class="kf_l">
              <!-- <img src="@/assets/icon/zxkf.png" alt="出错了" />
              <div class="text">
                <span style="color: #666">欢迎使用</span>
                <span style="color: #30b824; margin-top: 3px">在线客服</span>
              </div> -->
            </div>
            <div class="kf_r" style="float: right;">
              <img src="@/assets/icon/dh.png" alt="出错了" />
              <div class="text">
                <div style="color: #666">客服服务热线</div>
                <div style="color: #ff7c32; margin-top: 3px; font-weight: bold">
                  021-69160692
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="classify">
          <!-- 菜单  -->
          <transition name="fade" mode="out-in">
            <div class="menu" v-if="menuShow || menuShowClick" @mouseleave="mouseLeave">
              <!-- 左侧菜单 -->
              <div class="menu_l">
                <el-skeleton animated v-if="goodsArr.length == 0" :throttle="500">
                  <template slot="template">
                    <el-skeleton-item variant="p" style="width: 180px; height: 20px;margin: 10px 0 0 10px;"
                      v-for="(s, index) in 14" :key="index" />
                  </template>
                </el-skeleton>
                <div class="menu_l_son" :class="[item.isShow2?'hoverBar':'']" @mouseover="mouseOver(item)" 
                  v-for="(item, index) in goodsArr" :key="index">
                  <div>{{ item.typeName }}</div>
                  <i class="el-icon-arrow-right i" style="color: #666666"></i>
                </div>
              </div> 
              <!-- @mouseleave="mouseLeave2(item)" -->
              <div class="menu_zj_content" ref="menu_zj_content" >
                <div class="content" v-for="(idem, index) in goodsArrTwo" :key="index">
                  <div class="content_l">
                    <div>{{ idem.typeName }}</div>
                    <i class="el-icon-arrow-right" style="color: #666666"></i>
                  </div>
                  <div class="content_r">
                    <div v-for="(ipem, index) in idem.children" :key="index" @click="clickType(ipem)">{{ ipem.typeName }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="menu_r" ref="menu_r">
                <div class="pplog" v-for="(i, index) in brandList" :key="index">
                  <div class="img">
                    <img :src="i.logo" @click="PPClick(i)" alt="">
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="arrClass" @click="arrTab" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
            <img src="@/assets/icon/fenl.png" alt="出错了" />
            <div class="text">全部商品分类</div>
          </div>
          <div class="tabs" v-for="(item, index) in tabArr" :key="index">
            <div :class="[item.act ? 'tabsAct' : '']" @click="tabsClick(index)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getclassificationOfGoods, getclassificationOfBrand } from "@/utils/api/headApi/index"
export default {
  name: "largeHead",
  data() {
    return {
      inpVal: "",
      tabArr: [
        {
          name: "首页",
          act: false,
        },
        {
          name: "精品商城",
          act: false,
        },
        {
          name: "品牌中心",
          act: false,
        },
        {
          name: "商家入驻",
          act: false,
        },
        {
          name: "领券中心",
          act: false,
        },
        {
          name: "数控必抢",
          act: false,
        },
        {
          name: "品牌专享",
          act: false,
        },
        {
          name: "VR展会",
          act: false,
        },
        {
          name: "榜单",
          act: false,
        },
        {
          name: "限时秒杀",
          act: false,
        },
      ],
      menuShowClick: false,
      menuShow: false,
      // 分类 一级
      goodsArr: [],
      // 二级
      goodsArrTwo: [],
      // 品牌列表
      brandList: [],
      // 搜索历史
      keyVallueSous: [],
      // 购物车长度
      totalLength: 0,
      // 购物车
      shoppingCartList: '',

    };
  },
  props: {
    // menuShow: Boolean,
    tabArrShow: Number
  },
  computed: {
    currentPage() {
      return this.$store.state.currentPage; // 获取当前页面信息
    }
  },
  watch: {
    currentPage(newPage, oldPage) {
      this.menuShow = newPage
    },
    '$store.state.shoppingCartList'(newVal, oldVal) {
      this.shoppingCartList = this.$store.state.shoppingCartList
      // console.log(this.shoppingCartList, '购物车')
      this.totalLength = this.shoppingCartList.reduce((acc, obj) => {
        return acc + obj.mallOrderItemVos.length;
      }, 0);
    },
    '$route.path': function (newPath, oldPath) {
      if (this.$route.query.actNum) {
        this.tabArr.map(p => p.act = false)
        this.tabArr[this.$route.query.actNum].act = true
      }
    }
  },
  mounted() {
    this.$store.commit("getUserInfoData")
    this.tabArr.map(p => p.act = false)
    this.tabArr[this.$route.query.actNum || 0].act = true
    this.getGoods()
    this.getBrand()
    this.inpVal = this.$route.query.keyWords
    // 从本地缓存中取出字符串并转换为数组
    this.keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous'));
    console.log(this.keyVallueSous)

    this.menuShow = this.$store.state.currentPage
    // if (isNaN(this.tabArrShow)) return
    // this.tabArr[this.tabArrShow].act = true
  },
  methods: {
    goHome() {
      this.$router.push("/hoveContent?actNum=0")
    },
    logOut() {
      this.$confirm('确认退出登录吗?', '退出', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('historyRoute');
        this.$router.push('/')
        location.reload()
        // this.$message.success('请重新登录')     
      })
    },
    logIn() {
      localStorage.setItem('historyRoute', this.$route.fullPath);
      this.$router.push('/login')
    },
    // 跳转品牌
    PPClick(i) {
      window.open(`#/search2?keyWords=${i.brandName}&type=brand`, '_blank');
    },
    // 返回首页
    shouYe() {
      this.$router.push('/hoveContent?actNum=0')
    },
    // 点击历史搜索
    sousKeyClick(typeName, id) {
      if (this.$route.path == "/search") {
        this.$router.push(`/search?id=${id}&typeName=${typeName}`)
        location.reload()
      } else {
        window.open(`#/search?id=${id}&typeName=${typeName}`, '_blank')
      }
      return
      this.inpVal = i
      let keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous')) || [];
      let index = keyVallueSous.indexOf(this.inpVal)
      if (index !== -1) {
        keyVallueSous.splice(index, 1);
        keyVallueSous.unshift(this.inpVal);
      } else {
        keyVallueSous.unshift(this.inpVal)
      }
      if (keyVallueSous.length > 5) {
        keyVallueSous.splice(5, keyVallueSous.length - 5);
      }
      // 将数组转换为字符串并存入本地缓存中
      localStorage.setItem('keyVallueSous', JSON.stringify(keyVallueSous));
      window.open(`#/search2?keyWords=${i}`, '_blank');
    },
    // 获取商品分类
    async getGoods() {
      const res = await getclassificationOfGoods()
      if (res.statusCode == 8201) {
        this.goodsArr = res.data 
        this.goodsArr.map(o => { 
          this.$set(o,'isShow2',false)
        })
        this.$store.commit('getGoodsArrNav', this.goodsArr)
      }
    },
    // 获取品牌列表
    async getBrand() {
      const res = await getclassificationOfBrand({ size: 6, current: 1 })
      if (res.statusCode == 8201) return this.brandList = res.data.records
    },
    // tab栏切换
    tabsClick(index) {
      this.menuShowClick = false
      this.tabArr.map((t) => (t.act = false));
      this.tabArr[index].act = true;
      if (index == 0) return this.$router.push('/?actNum=0')
      if (index == 1) return this.$router.push('/boutiqueMall?actNum=1')
      if (index == 2) return this.$router.push('/brandCenter?actNum=2')
      if (index == 3) return this.$router.push('/merchantSettlement?actNum=3')
      if (index == 4) return this.$router.push('/voucherCollectionCenter?actNum=4')
      if (index == 5) return this.$router.push('/CNCMustGrab?actNum=5')
      if (index == 6) return this.$router.push('/brandExclusive?actNum=6')
      if (index == 7) return this.$router.push('/moreExhibitions?actNum=7')
      if (index == 8) return this.$router.push('/rankingList?actNum=8')
      if (index == 9) return this.$router.push('/limitedTimeFlashKill?actNum=9')
    },
    arrTab() {
      this.menuShowClick = !this.menuShowClick
    },
    handleMouseEnter() {
      this.menuShowClick = true
    },
    handleMouseLeave() {
      // this.menuShowClick = false
      setTimeout(() => {
        if (this.$refs.menu_zj_content.style.opacity != 1) {
          this.menuShowClick = false
        }
      }, 100)

    },
    // 左侧菜单数遍移入移出事件
    // 移入
    mouseOver(i) {
      this.goodsArr.map(n => {
        if(n.id == i.id){
          n.isShow2 = true
        }else{
          n.isShow2 = false
        }
      })
      this.goodsArrTwo = i.children
      this.$refs.menu_zj_content.style.opacity = 1
      this.$refs.menu_r.style.opacity = 1
      this.$refs.menu_zj_content.style.zIndex = 999
      this.$refs.menu_r.style.zIndex = 999
    },
    // 移出
    mouseLeave(n) {
      this.goodsArr.map(n=>n.isShow2 = false)
      this.menuShowClick = false
      this.$refs.menu_zj_content.style.opacity = 0
      this.$refs.menu_r.style.opacity = 0
      this.$refs.menu_zj_content.style.zIndex = -1
      this.$refs.menu_r.style.zIndex = -1
    },
    handleEnterKey() {
      this.sousuo()
    },
    // 点击搜索
    sousuo() {
      // this.$router.push("/search")
      if (!this.inpVal) return this.$message({
        message: "请输入关键词"
      })
      let keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous')) || [];
      let index = keyVallueSous.indexOf(this.inpVal)
      if (index !== -1) {
        keyVallueSous.splice(index, 1);
        keyVallueSous.unshift(this.inpVal);
      } else {
        keyVallueSous.unshift(this.inpVal)
      }
      // if (!keyVallueSous.includes(this.inpVal)){
      //   keyVallueSous.unshift(this.inpVal)
      // }
      if (keyVallueSous.length > 5) {
        keyVallueSous.splice(5, keyVallueSous.length - 5);
      }
      // 将数组转换为字符串并存入本地缓存中
      localStorage.setItem('keyVallueSous', JSON.stringify(keyVallueSous));
      if (this.$route.path == "/search2") {
        this.$router.push(`/search2?keyWords=${this.inpVal}`)
        location.reload()
      } else {
        window.open(`#/search2?keyWords=${this.inpVal}`, '_blank');
      }

      // this.$router.push(`/search2?keyWords=${this.inpVal}`)
    },
    // 跳转到我的订单页面
    myOrder() {
      window.open('#/myIndex/myOrder?actMyNum=0', '_blank');
    },
    // 跳转到帮助中心页面
    helpCenter() {
      window.open('#/helpCenter', '_blank');
    },
    // 跳转到我的收藏
    myCollect() {
      window.open('#/myIndex/myCollect?actMyNum=5', '_blank');
    },
    // 跳转购物车
    shoppingCart() {
      window.open('#/myShoppingCart', '_blank');
    },
    // 点击分类
    clickType(item) {
      console.log(item)
      window.open(`#/search?id=${item.id}&typeName=${item.typeName}`, '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 180px;

  >.top {
    width: 100%;
    height: 30px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;

    >.among {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: block !important;

      >.userInfo {
        width: auto;
        float: right;
        line-height: 30px;
        font-size: 12px;
        color: #333333;
        display: flex;

        >div {
          cursor: pointer;
          transition: all 0.3s;
        }

        >div:hover {
          color: #ff4242;
          transition: all 0.3s;
        }
      }
    }
  }

  >.bot {
    width: 100%;
    height: 150px;
    background-color: #fff;
    border-bottom: 3px solid #ff4242;

    >.among {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      padding-top: 24px;
      box-sizing: border-box;

      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      >.box {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;

        >.l {
          width: 240px;
          cursor: pointer;
        }

        >.z {
          display: flex;

          >.inp_content {
            width: 420px;
            height: 36px;
            border-radius: 4px;
            border: 2px solid #ff4242;
            box-sizing: border-box;

            .inp {
              width: 100%;
              height: 100%;
              display: flex;

              >input {
                width: 340px;
                height: 30px;
                background-color: #f9f9f9;
                border: 0px;
                border-radius: 4px 0 0 4px;
                padding-left: 10px;
                outline: none;
              }

              >.btn {
                width: 80px;
                background-color: #ff4242;
                text-align: center;
                line-height: 32px;
                color: #ffffff;
                cursor: pointer;
              }
            }

            >.text {
              width: 120%;
              font-size: 12px;
              color: #666;
              margin-top: 6px;

              >span {
                margin-right: 20px;
              }
            }
          }

          >.gwc {
            width: 100px;
            height: 36px;
            margin-left: 5px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;

            >img {
              width: 15px;
              margin-right: 2px;
            }

            >.text {
              font-size: 12px;
              color: #ff4242;
            }

            >.tab {
              width: auto;
              height: 12px;
              padding: 0 7px;
              box-sizing: border-box;
              border-radius: 16px;
              background-color: #ff4242;
              text-align: center;
              line-height: 14px;
              font-size: 10px;
              color: #fff;
              position: absolute;
              top: -5px;
              right: -5px;
            }
          }
        }

        >.r {
          width: 260px;
          height: 48px;
          background-color: #f5f5f5;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          box-sizing: border-box;

          >.kf_l {
            display: flex;

            >img {
              width: 32px;
              height: 32px;
              margin-right: 10px;
            }

            >.text {
              width: 48px;
              font-size: 12px;
            }
          }

          >.kf_r {
            display: flex;

            >img {
              width: 32px;
              height: 32px;
              margin-right: 10px;
            }

            >.text {
              width: 92px;
              font-size: 12px;
            }
          }
        }
      }

      >.classify {
        width: 100%;
        height: 40px;
        display: flex;
        position: relative;
        margin-top: 35px;

        >.menu {
          width: 1200px;
          height: 470px;
          position: absolute;
          left: 0px;
          // bottom: -480px;
          top: 45px;
          display: flex;
          // z-index: -1;

          >.menu_l {
            width: 210px;
            height: 470px;
            border-radius: 4px;
            font-size: 14px;
            color: #333;
            // border-right: 1px solid #d9d9d9;
            overflow-y: auto;
            background-color: #fff;
            z-index: 999;

            >.menu_l_son {
              width: 100%;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 20px;
              box-sizing: border-box;
              cursor: pointer;
              transition: all 0.3s;

              >.i {
                opacity: 0;
              }
            }

            >.menu_l_son:hover {
              color: #ff4242;
              background-color: #f4f4f4;
              transition: all 0.3s;

              >.i {
                opacity: 1;
                transition: all 0.3s;
              }
            }
          }

          .menu_zj_content {
            width: 780px;
            height: 470px;
            z-index: -1;
            box-sizing: border-box;
            overflow-y: auto;
            padding: 10px 0;
            box-sizing: border-box;
            opacity: 0;
            background-color: #fff;
            transition: all .3s;

            >.content {
              width: 100%;
              height: auto;
              display: flex;
              margin-bottom: 10px;

              >.content_l {
                height: auto;
                width: 120px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                margin-left: 20px;
                padding-top: 4px;

                >div {
                  font-weight: bold;
                  font-size: 12px;
                }
              }

              >.content_r {
                width: 80%;
                font-size: 12px;
                color: #666;
                display: flex;
                flex-wrap: wrap;
                margin-left: 32px;
                line-height: 20px;

                >div {
                  margin-right: 20px;
                  cursor: pointer;
                }

                >div:hover {
                  color: #ff4242;
                  transition: all 0.3s;
                }
              }
            }
          }

          >.menu_r {
            width: 220px;
            height: 470px;
            z-index: -1;
            background-color: #fff;
            opacity: 0;
            transition: all .3s;
            padding-top: 20px;
            box-sizing: border-box;

            >.pplog {
              width: 168px;
              height: 64px;
              text-align: center;
              line-height: 64px;
              margin: 0 auto;
              cursor: pointer;
              margin-bottom: 8px;
              border: 1px solid #E0E0E0;
              display: flex;
              align-items: center;
              justify-content: center;

              >.img {
                width: 102px;
                height: 38px;

                >img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transition: all .3s;
                }
              }

              >.img:hover {
                >img {
                  transform: scale(1.2);
                  transition: all .3s;
                }
              }
            }
          }
        }

        >.arrClass {
          width: 210px;
          height: 40px;
          border-radius: 4px 4px 0 0;
          background-color: #ff4242;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          cursor: pointer;
          color: #fff;

          >.text {
            margin-left: 6px;
          }

          >img {
            margin-top: 2px;
          }
        }

        >.tabs {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 30px;
          font-size: 16px;
          color: #333;

          >div {
            cursor: pointer;
            font-weight: 400;
            transition: all 0.3s;
          }

          >div:hover {
            cursor: pointer;
            color: #ff4242;
            transition: all 0.3s;
          }
        }
      }
    }
  }
}

.sousuoKey {
  cursor: pointer;
  transition: all .3s;
}

.sousuoKey:hover {
  color: red;
  transition: all .3s;
}

.tabsAct {
  color: #ff4242;
  font-weight: 700 !important;
  transition: all 0.3s;
}

/*里面的代码可以根据自己需求去进行更改*/
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 4px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #d9d9d9;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.hoverBar {
  color: #ff4242 !important;
  background-color: #f4f4f4 !important;
}
</style>