import Vue from 'vue'
import Vuex from 'vuex'

import {
  getOfficialProprietaryRecommendedList,
  getIsKillRecommendedList,
  getVrExhibitionList,
  getQualityGoodsShoppingList,
  getListOfCoupons,
  getStoreClassification,
  getNoticeList,
  getCNCMustGrab,
  getCNCMustGrabRob,
  getCNCMustGrabInfo,
  getLimitedTimeFlashKillTimeList,
  getTimeFlashKilling,
  getFlashKillingCommodity,
  gettNoticeType
} from "@/utils/api/homeApi/index"
import { getclassificationOfBrand } from "@/utils/api/headApi/index"
import { getUserInfo, getAShoppingCart, getOrderList } from "@/utils/api/myApi/index"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openId: 'wx5c51ed60332045dd',
    baseURL: 'https://api.cnc-x.cn',
    returnUrl: 'https://www.cnc-x.cn/#/myIndex/myOrder?actMyNum=0', // 支付宝支付成功后跳转的页面
    currentPage: false, // 是否显示导航左侧菜单
    officialProprietaryRecommendedList: [], // 官方自营推荐列表
    isKillRecommendedList: [], // 正在秒杀推荐列表
    VrList: [], // vr展会列表
    shoppingList: [], // 精品商城列表
    shoppingListTotal: 0,
    listOfCoupons: [], // 优惠券列表
    brandList: [], // 热门品牌列表
    commodityInfo: {}, // 商品详情信息
    mallStoreInfo: {}, // 店铺详细信息
    mallStoreTitle: '', // 店铺内搜索的内容
    isFans: false, // 是否关注店铺
    storeClassificationList: [], // 店铺的商品分类
    // storeHotSales:[], //店铺热销商品
    userInfo: {}, // 用户信息
    shoppingCartList: [], // 购物车列表
    orderListInfoDFH: {}, // 订单待发货
    orderListInfoDSH: {}, // 订单待收货
    orderListInfoDZF: {}, // 订单待付款
    goodsArrNav: [], // 一级分类列表
    noticeList: {}, // 公告列表
    CNCMustGrabInfo: [], // 首页数控必抢数据
    storeId: '', // 商家id
    socketValueArr: {}, // socket内容
    socketSendValue: {}, // socket内容
  },
  mutations: {
    // 是否显示导航左侧菜单
    setCurrentPage(state, value) {
      state.currentPage = value;
    },

    // socket内容
    socketValue(state, value) {
      if (!value.mt) return
      if (value.mt == 500) {

      } else {
        state.socketValueArr = value
      }
    },

    // 上传信息
    socketSendValueClick(state, value){
      state.socketSendValue = value
    },

    // 回到顶部
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    // 店铺内搜索内容
    mallStoreTitleSouS(state, value) {
      state.mallStoreTitle = value;
    },

    // 获取公告列表 
    async getNoticeListClick(state, value) {
      const res = await getNoticeList(value)
      if (res.statusCode == 8201) {
        state.noticeList = res.data.records
        let ids = res.data.records.map(obj => obj.categoryId).join(",")
        const rej = await gettNoticeType(ids)
        state.noticeList.map(o => {
          rej.data.map(p => {
            if (o.categoryId == p.id) {
              o.typeName = p.name
            }
          })
        })
      }
    },

    // 数控必抢
    async getCNCMustGrabInfo(state, value) {
      let tabArr = [];
      // const res = await getCNCMustGrab();
      // if (res.statusCode == 8201) {
      //   const promises = res.data.map(async (d) => {
      //     if (d.itemId) {
      //       const rej = await getCNCMustGrabRob(d.itemId);
      //       if (rej.statusCode == 8201) {
      //         rej.data.map((b, index) => {
      //           tabArr.push(b);
      //           tabArr[0].act = true;
      //         });
      //       }
      //     }
      //   });
      //   await Promise.all(promises);
      const rej = await getCNCMustGrabRob('');
      if (rej.statusCode == 8201) {
        rej.data.map( async (b, index) => {
          tabArr.push(b);
        });
        const ret = await getCNCMustGrabInfo({
          pageSize: 3,
          currentPage: 1,
          categoryId: tabArr[0].id
        })
        if (ret.statusCode == 8201) {
          state.CNCMustGrabInfo = ret.data.records
        }
      }
      // }
    },

    // 官方自营推荐列表
    async getOfficialList(state, value) {
      const res = await getOfficialProprietaryRecommendedList(value)
      if (res.statusCode == 8201) return state.officialProprietaryRecommendedList = res.data
    },
    // 正在秒杀推荐列表
    async getRecommendedList(state, value) {
      // const res = await getIsKillRecommendedList(value)
      // if (res.statusCode == 8201) return state.isKillRecommendedList = res.data.records
      const res = await getLimitedTimeFlashKillTimeList(1)
      if (res.statusCode == 8201) {

        let time = res.data[0].seckillHall
        const rej = await getTimeFlashKilling({ time, seckillType: 1 })
        if (rej.statusCode == 8201) {
          const red = await getFlashKillingCommodity({
            current: 1,
            size: 3,
            id: rej.data[0].mallSeckillHallList[0].seckillHallId
          })
          if (rej.statusCode == 8201) {
            // 日期转换
            function formatTime(num) {
              const hours = Math.floor(num / 60);
              const minutes = num % 60;
              const hoursStr = hours < 10 ? `0${hours}` : String(hours);
              const minutesStr = minutes < 10 ? `0${minutes}` : String(minutes);
              return `${minutesStr}:${hoursStr}`;
            }
            let endTime = new Date(res.data[0].mallSeckillHallList[0].endTime).getTime()  // 结束时间
            let startTime = new Date(`${rej.data[0].seckillHall} ${formatTime(rej.data[0].mallSeckillHallList[0].hallTime)}`).getTime() // 开始时间
            const currentTimestamp = new Date().getTime(); // 当前时间
            red.data.records.map(m => {
              if (currentTimestamp < startTime) {
                // Vue.$set(m, 'typeTime', '0') // 未开始
                m.typeTime = 0
              } else if (currentTimestamp > endTime) {
                // Vue.$set(m, 'typeTime', '2') // 已结束
                m.typeTime = 2
              } else {
                // Vue.$set(m, 'typeTime', '1') // 进行中
                m.typeTime = 1
              }
            })
            state.isKillRecommendedList = red.data.records
            console.log(state.isKillRecommendedList, 'vuex',)
          }
        }
      }

    },
    // vr展会推荐列表
    async getVrList(state, value) {
      const res = await getVrExhibitionList(value)
      if (res.statusCode == 8201) return state.VrList = res.data.records
    },
    // 精品商城列表
    async getShoppingList(state, value) {
      const res = await getQualityGoodsShoppingList(value)
      if (res.statusCode == 8201) {
        state.shoppingList = res.data.records
        state.shoppingListTotal = res.data.total
      }
    },
    // 优惠券列表
    async getOfCoupons(state, value) {
      const res = await getListOfCoupons(value)
      if (res.statusCode == 8201) return state.listOfCoupons = res.data.records
    },
    // 获取热门品牌列表
    async getBrand(state, value) {
      const res = await getclassificationOfBrand(value)
      if (res.statusCode == 8201) return state.brandList = res.data.records
    },
    // 获取商品详细信息
    async getCommodity(state, value) {
      state.commodityInfo = value
      const res = await getStoreClassification(value.mallStore.storeId)
      if (res.statusCode == 8201) {
        state.storeClassificationList = res.data
      }
    },
    // 获取店铺详细信息
    getStore(state, value) {
      state.mallStoreInfo = value
    },
    // 是否关注店铺
    getIsFans(state, value) {
      state.isFans = value
    },
    // 获取用户信息
    async getUserInfoData(state, value) {
      const res = await getUserInfo()
      if (res.statusCode == 8201) {
        state.userInfo = res.data
      }
    },
    // 购物车列表
    async getAShoppingCartList(state, value) {
      const res = await getAShoppingCart()
      if (res.statusCode == 8201) {
        state.shoppingCartList = res.data
      }
    },
    // 我的订单列表
    async getOrderListInfo(state, value) {
      const res = await getOrderList(value)
      if (res.statusCode == 8201) {
        if (value.type == 'DFH') {
          state.orderListInfoDFH = res.data
        }
        if (value.type == 'DSH') {
          state.orderListInfoDSH = res.data
        }
        if (value.type == 'DZF') {
          state.orderListInfoDZF = res.data
        }
      }
    },
    // 一级分类列表
    getGoodsArrNav(state, value) {
      state.goodsArrNav = value
    }

  },
  actions: {
  },
  modules: {
  }
})
