import request from '@/utils/request'

// 头部接口

// 获取商品分类
export function getclassificationOfGoods() {
  return request({
    url: `/user/goods-category/listByCategory`,
    method: 'get',
  })
}

// 获取品牌分类
export function getclassificationOfBrand(paging) {
    return request({
      url: `/user/goods-brand/listIsBrand?size=${paging.size}&current=${paging.current}&typeId=${paging.typeId || '' }&isBrand=${paging.isBrand ? true : false}`,
      method: 'get',
    })
  }