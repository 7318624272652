<template>
  <div class="home">
    <!-- 大头部 -->
    <largeHead :menuShow='menuShow' :tabArrShow="tabArrShow" />

    <!-- 首页主体内容 -->
    <!-- <hoveContent /> -->
    <router-view />

    <!-- 固定按钮
    <div class="fixed">

      <div class="son son2">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/ewm.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/ewmA.png" alt="">
        </div>
        <div class="text">手机购</div>
        <div class="ewm">
          <div class="img">
            <img src="https://image.cnc-x.cn/brand/20220923102224.png" alt="">
          </div>
          <div class="text">扫一扫下载app</div>
        </div>
      </div>
      <div class="son">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/gwc.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/gwcA.png" alt="">
        </div>
        <div class="text">购物车</div>
      </div>
      <div class="son">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/kf.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/kfA.png" alt="">
        </div>
        <div class="text">客服</div>
      </div>
      <transition name="fade">
        <div class="son back-to-top" @click="scrollToTop" v-if="showButton">
          <div class="img">
            <img class="img1" src="@/assets/hoveIcon/hd.png" alt="">
            <img class="img2" src="@/assets/hoveIcon/hdA.png" alt="">
          </div>
          <div class="text">顶部</div>
        </div>
      </transition>
    </div> -->

    <!-- 底部 -->
    <bottomContent></bottomContent>
  </div>
</template>

<script>
import largeHead from "@/components/largeHead.vue";
import hoveContent from "@/components/hoveContent.vue";
import bottomContent from "@/components/bottomContent.vue";

export default {
  name: "Home",
  components: {
    largeHead,
    hoveContent,
    bottomContent
  },
  data() {
    return {
      // 是否默认展开头部菜单
      menuShow: true,
      // 头部激活菜单
      tabArrShow: 0,
      // 是否显示回顶按钮
      showButton: false,
    };
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    // console.log(this.$route.path, "当前页面");
    // if (this.$route.path == '/') {
    //   this.menuShow = true
    // }
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 返回顶部
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showButton = window.scrollY > 300;
      const wrapper = this.$refs.wrapper
      const isBottom = window.scrollY - wrapper.scrollHeight
      if (isBottom > 1200) {
        this.recommendPaging.current++
        this.getrecommendShool()
      }
    },
  }
};
</script>

<style lang="less" scoped>

.fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s ease-in-out;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
.home {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
}

  // 固定按钮
  .fixed {
    width: 54px;
    height: 270px;
    position: fixed;
    top: 600px;
    right: 0;
    // z-index: 999;


    >.son {
      width: 54px;
      height: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-bottom: 2px;
      cursor: pointer;

      >.ewm {
        width: 97px;
        height: 120px;
        background-color: #fff;
        padding: 8px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: -100px;
        opacity: 0;
        transition: all .3s;

        >.img {
          width: 80px;
          height: 80px;

          >img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        >.text {
          font-size: 10px;
          color: #333;
          margin-top: 8px;
        }
      }

      >.img {
        width: 20px;
        height: 20px;
        position: relative;

        >img {
          position: absolute;
          top: 0;
          left: 0;
        }

        >.img1 {
          opacity: 1;
          transition: all .3s;
        }

        >.img2 {
          opacity: 0;
          transition: all .3s;
        }
      }

      >.text {
        font-size: 12px;
        color: #333;
        margin-top: 3px;
        transition: all .3s;
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s ease-in-out;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }



    >.son2:hover {
      >.ewm {
        opacity: 1;
      }
    }

    >.son:hover {
      .img1 {
        opacity: 0;
        transition: all .3s;
      }

      .img2 {
        opacity: 1;
        transition: all .3s;
      }

      >div {
        color: #FF4242;
        transition: all .3s;
      }
    }
  }
</style>
