import request from '@/utils/request'

// 首页接口

// 获取轮播图
export function getBannerList(type) {
    return request({
        url: `/mall/mall-homepage/homePage?category=${type}`,
        method: 'get',
    })
}

// 获取官方自营推荐列表
export function getOfficialProprietaryRecommendedList(paging) {
    return request({
        url: `/mall/mall-homepage/homePageGFDSpu?pageSize=${paging.size}&currentPage=${paging.current}`,
        method: 'get',
    })
}

//  正在秒杀推荐列表
export function getIsKillRecommendedList(paging) {
    return request({
        url: `/mall/mallSeckillinfo/page2?size=${paging.size}&current=${paging.current}`,
        method: 'get',
    })
}

//  vr展会列表
export function getVrExhibitionList(paging) {
    return request({
        url: `/mall/vr-zh/subList?pageSize=${paging.size}&currentPage=${paging.current}`,
        method: 'get',
    })
}
// 根据展会id获取展馆信息
export function getPavilionInformation(data) {
    return request({
        url: `/mall/vr-zg/subList?pageSize=${data.size}&currentPage=${data.current}&zhId=${data.zhId}`,
        method: 'get',
    })
}
// 根据展馆id获取单个展馆的具体信息
export function getPavilionSpecificInformation(data) {
    return request({
        url: `/mall/vr-zd/subList?pageSize=${data.size}&currentPage=${data.current}&zgId=${data.zgId}`,
        method: 'get',
    })
}

//  精品商城列表
export function getQualityGoodsShoppingList(paging) {
    return request({
        url: `/mall/mall-homepage/listByStore?pageSize=${paging.size}&currentPage=${paging.current}`,
        method: 'get',
    })
}
//  优惠券列表
export function getListOfCoupons(paging) {
    let url = ''
    if(paging.type){
        url = `/mall/couponinfo/page2?size=${paging.size}&current=${paging.current}&share=${paging.share || ''}&type=${paging.type}`
    } else {
        url = `/mall/couponinfo/page2?size=${paging.size}&current=${paging.current}&share=${paging.share || ''}`
    }
    return request({
        url: url,
        method: 'get',
    })
}
//  购物车优惠券列表
export function getListOfCouponsGWC(paging) {
    let url = `/mall/couponinfo/page2?size=${paging.size}&current=${paging.current}&share=${paging.share}&storeId=${paging.storeId}&spuIds=${paging.spuIds}`
    return request({
        url: url,
        method: 'get',
    })
}

// 领取优惠券
export function postClaimCoupons(data) {
    return request({
        url: `/mall/couponuser/save2`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify({  // 将数据转换为JSON字符串
            couponId: data.id,
            storeId: data.storeId
        })
    })
}

//  首页为你推荐商品列表
export function getRecommendShoolList(paging) {
    return request({
        url: `/mall/mall-spu/spuHot?pageSize=${paging.size}&currentPage=${paging.current}`,
        method: 'get',
    })
}

// 商品详情页 
export function getCommodityProductDetails(ids) {
    return request({
        url: `/mall/mall-spu/spuParticulars?supId=${ids}`,
        method: 'get',
    })
}

// 根据商品id查看可用优惠券 
export function getCommodityCoupons(data) {
    return request({
        url: `/mall/couponinfo/page2?spuIds=${data.spuId}&storeId=${data.storeId}`,
        method: 'get',
    })
}

// 点击收藏
export function postCollectSave(spuId) {
    return request({
        url: `/mall/mall-user-collect/save`,
        method: 'post',
        data: {
            spuId
        }
    })
}
// 取消收藏
export function deleteCollectDel(spuId) {
    return request({
        url: `/mall/mall-user-collect/del`,
        method: 'delete',
        data: {
            spuId
        }
    })
}

// 获取店铺详细信息
export function getStoreInfo(storeId) {
    return request({
        url: `/mall/mall-store/storeStatus?storeId=${storeId}`,
        method: 'get',
    })
}

// 获取店铺楼层
export function getshopFloor(ids) {
    return request({
        url: `/mall/mallStorehome/list?storeId=${ids}`,
        method: 'get',
    })
}

// 根据楼层id获取当前id的数据信息 (除VR展店 新品推荐 榜单优选 限时秒杀)
export function getShopFloorInfo(data) {
    return request({
        url: `/mall/mallStoreBinding/page2?storeId=${data.storeId}&homeId=${data.homeId}&current=1&size=10`,
        method: 'get',
    })
}
// 根据楼层id获取当前id的数据信息 VR展店
export function getShopFloorInfoVR(data) {
    return request({
        url: `/mall/vr-zd/list?storeId=${data.storeId}&isHomePage=true`,
        method: 'get',
    })
}
// 根据楼层id获取当前id的数据信息 新品推荐 榜单优选
export function getShopFloorInfoNewProductRecommendationListTheOptimization(data) {
    return request({
        url: `/mall/mall-store/selectBySpu?storeId=${data.storeId}&type=${data.type}&current=1&size=10`,
        method: 'get',
    })
}
// 根据楼层id获取当前id的数据信息 限时秒杀
export function getShopFloorInfoLimitedTimeSecondsKill(data) {
    return request({
        url: `/mall/mallSeckillhall/list?storeId=${data.storeId}&hallDate=${data.hallDate || ''}`,
        method: 'get',
    })
}

// 获取店铺商品信息
export function getStoreCommodity(data) {
    return request({
        url: `/mall/mall-store/selectBySpu?title=${data.title}&type=${data.type}&storeCategoryId=${data.storeCategoryId}&storeId=${data.storeId}&spuIds=${data.spuIds}&currentPage=${data.currentPage}&pageSize=${data.pageSize}`,
        method: 'get',
    })
}

// 店铺的订阅与取消（关注）
export function getStoreFollowSubscription(data) {
    return request({
        url: `/mall/mall-store/storeFans?storeId=${data.ids}&type=${data.type}`,
        method: 'get',
    })
}

// 获取店铺的商品分类
export function getStoreClassification(ids) {
    return request({
        url: `/mall/mall-store-category/listByCategory?storeId=${ids}`,
        method: 'get',
    })
}

// 获取店铺热销商品
export function getStoreHotSales(data) {
    return request({
        url: `/mall/mall-store/selectBySpu?storeId=${data.storeId}&currentPage=${data.currentPage}&pageSize=${data.pageSize}&type=${data.type}&spuIds=${data.spuIds || ''}`,
        method: 'get',
    })
}

// 获取商品评价
export function getCommodityEvaluate(data) {
    return request({
        url: `/mall/mall-spu/spuComment?supId=${data.supId}&currentPage=${data.currentPage}&pageSize=${data.pageSize}`,
        method: 'get',
    })
}

// 加入购物车
export function getAddCart(data) {
    return request({
        url: `/mall/mall-cart/addCart?skuId=${data.skuId}&num=${data.num}`,
        method: 'get',
    })
}

// 获取品牌专享
export function getBrandExclusive(paging) {
    return request({
        url: `/mall/mall-homepage/homePageBrandDetails?currentPage=${paging.currentPage}&pageSize=${paging.pageSize}`,
        method: 'get',
    })
}

// 根据分类id获取对应商品
export function getGoodsTypeId(data) {
    return request({
        url: `/mall/mall-spu/categoryBySpu?type=${data.type}&id=${data.id}&currentPage=${data.currentPage}&pageSize=${data.pageSize}`,
        method: 'get',
    })
}

// 根据搜索关键词获取对应商品
export function getGoodsSearchKeyword(data) {
    return request({
        url: `/es/product/query?keyword=${data.keyWord}&sPage=${data.sPage}&size=${data.size}&mold=${data.mold}`,
        method: 'get',
    })
}

// 查询搜索第一个商品的分类对应参数
export function getGoodsCategoryParamer(ids) {
    return request({
        url: `/es/product/categoryParamer?categoryId=${ids}`,
        method: 'get',
    })
}

// 根据品牌搜索商品 
export function getGoodsSearchBrand(data) {
    return request({
        url: `/mall/mall-spu/brandBySpu?brandName=${data.keyWord}&type=${data.type}&currentPage=${data.sPage}&pageSize=${data.size}`,
        method: 'get',
    })
}


// 数控必抢接口1
export function getCNCMustGrab() {
    return request({
        url: `/mall/mall-homepage/homePage?category=ROBBANNER`,
        method: 'get',
    })
}
// 数控必抢接口2
export function getCNCMustGrabRob(typeId) {
    return request({
        url: `/mall/mall-homepage/homePageRob?typeId=${typeId}`,
        method: 'get',
    })
}
// 数控必抢接口3
export function getCNCMustGrabInfo(data) {
    return request({
        url: `/mall/mall-homepage/homePageRobSpu?pageSize=${data.pageSize}&currentPage=${data.currentPage}&categoryId=${data.categoryId}`,
        method: 'get',
    })
}


// 获取计量单位
export function getMeasurementTunit(val) {
    return request({
        url: `/user/data-dictionary/getResource?resource=mall&value=${val}`,
        method: 'get',
    })
}

// 提交寻源按钮
export function postSubmitSourcing(data) {
    return request({
        url: `/mall/mall-purchase-demand/save`,
        method: 'post',
        data
    })
}


// 榜单
export function getRankingList() {
    return request({
        url: `/mall/mall-homepage/homePageJuicing`,
        method: 'get'
    })
}

// 查询限时秒杀日期
export function getLimitedTimeFlashKillTimeList(seckillType) {
    return request({
        url: `/mall/mallSeckillhall/list?&seckillType=${seckillType}`,
        method: 'get'
    })
}

// 根据日期查询当天所有时间段秒杀
export function getTimeFlashKilling(data) {
    return request({
        url: `/mall/mallSeckillhall/list?hallDate=${data.time}&seckillType=${data.seckillType}`,
        method: 'get'
    })
}

// 根据时间id查询秒杀商品
export function getFlashKillingCommodity(data) {
    return request({
        url: `/mall/mallSeckillinfo/page2?current=${data.current}&size=${data.size}&seckillHallId=${data.id}`,
        method: 'get'
    })
}

// 插叙秒杀商品信息
export function getFlashKillingCommodityInfo(ids) {
    return request({
        url: `/mall/mallSeckillinfo/getById/${ids}`,
        method: 'get'
    })
}


// 获取公告列表
export function getNoticeList(data) {
    return request({
        url: `/mall/noticeinfo/page?current=${data.current}&size=${data.size}&descs=create_time&enable=1`,
        method: 'get'
    })
}

// 查询公告分类
export function gettNoticeType(ids) {
    return request({
        url: `/mall/noticecategory/getLists?noticeCategoryIds=${ids}`,
        method: 'get'
    })
}

