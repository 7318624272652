<template>
    <div>
        <div class="bottom">
            <div class="content">
                <div class="noe">
                    <img src="@/assets/logoSC.png" alt="">
                    <!-- <div class="btn">问题反馈</div> -->
                </div>
                <div class="two">
                    <div class="title">交易</div>
                    <div class="son" @click="help(7)">如何下单</div>
                    <div class="son" @click="help(6)">如何选型</div>
                    <div class="son" @click="help(9)">物流相关</div>
                    <div class="son" @click="help(10)">如何退款</div>
                </div>
                <div class="three">
                    <div class="title">使用</div>
                    <div class="son" @click="help(0)">如何注册</div>
                    <div class="son" @click="help(1)">关于商家入驻</div>
                    <div class="son" @click="help(0)">代理商申请</div>
                </div>
                <div class="four">
                    <div class="title">帮助</div>
                    <div class="son" @click="help(0)">使用帮助</div>
                    <div class="son" @click="help(0)">常见问题</div>
                    <div class="son" @click="help(0)">商家教程</div>
                </div>
                <div class="five">
                    <div class="title">
                        <div class="l">服务热线</div>
                        <div class="r">021-69160692</div>
                    </div>
                    <div class="son">联系邮箱：postmaster@gyv.cn</div>
                    <div class="son">联系地址：上海市浦东新区周浦镇星月智汇湾九号楼302</div>
                </div>
            </div>
            <div class="hao">@2019 - 2023 cnc-x.cn 版权所有 沪ICP备2021037330号-1</div>
        </div>
    </div>
</template>

<script>
export default {
methods:{
    help(index){
        window.open(`#/helpCenter?indexAct=${index}`, '_blank');
    }
}
}
</script>

<style lang="less" scoped>
.bottom {
    width: 100%;
    height: 200px;
    background-color: #fff;
    padding: 20px 0 13px 0;
    box-sizing: border-box;

    >.content {
        width: 1200px;
        height: 140px;
        display: flex;
        margin: 0 auto;
        justify-content: space-around;
        border-bottom: 1px solid rgba(153, 153, 153, .5);

        >.noe {
            width: 240px;
            height: auto;
            display: flex;
            align-items: center;

            >.img {
                width: 240px;
                height: 47px;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            >.btn {
                width: 107px;
                height: 34px;
                border: 1px solid #999;
                color: #999;
                font-size: 14px;
                text-align: center;
                line-height: 34px;
                margin: 0 auto;
                margin-top: 30px;
                cursor: pointer;
                transition: all .3s;
            }
            >.btn:hover {
                color: #FF4242;
                border: 1px solid #FF4242;
                transition: all .3s;
            }

        }

        >.two,.three,.four,.five {
            color: #666;

            >.title {
                font-size: 18px;
                margin-bottom: 17px;
                display: flex;
                align-items: flex-end;
                >.r {
                    font-weight: bold;
                    margin-left: 12px;
                }
            }

            >.son {
                font-size: 12px;
                margin-bottom: 5px;
                cursor: pointer;
                transition: all .3s;
            }
            >.son:hover {
                color: #FF4242;
                transition: all .3s;
            }
        }
    }
    >.hao {
        font-size: 12px;
        color: #666;
        text-align: center;
        margin-top: 10px;
    }
}</style>