import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index';

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  // 登录页面
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  // 首页
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    redirect: "hoveContent",
    children: [
      // 首页
      {
        path: '/hoveContent',
        name: 'hoveContent',
        component: () => import('../components/hoveContent.vue'),
        meta: {
          title: '首页',
        },
      },
      // 精品商城
      {
        path: '/boutiqueMall',
        name: 'boutiqueMall',
        component: () => import('../views/boutiqueMall.vue')
      },
      // 品牌中心 
      {
        path: '/brandCenter',
        name: 'brandCenter',
        component: () => import('../views/brandCenter.vue')
      },
      // 商家入驻
      {
        path: '/merchantSettlement',
        name: 'merchantSettlement',
        component: () => import('../views/merchantSettlement.vue')
      },
      // 领券中心 
      {
        path: '/voucherCollectionCenter',
        name: 'voucherCollectionCenter',
        component: () => import('../views/voucherCollectionCenter.vue')
      },
      // 数控必抢
      {
        path: '/CNCMustGrab',
        name: 'CNCMustGrab',
        component: () => import('../views/CNCMustGrab.vue')
      },
      // 品牌专享  
      {
        path: '/brandExclusive',
        name: 'brandExclusive',
        component: () => import('../views/brandExclusive.vue')
      },
      // 更多展会
      {
        path: '/moreExhibitions',
        name: 'moreExhibitions',
        component: () => import('../views/moreExhibitions.vue')
      },
      // 榜单
      {
        path: '/rankingList',
        name: 'rankingList',
        component: () => import('../views/rankingList.vue')
      },
      // 限时秒杀 
      {
        path: '/limitedTimeFlashKill',
        name: 'limitedTimeFlashKill',
        component: () => import('../views/limitedTimeFlashKill.vue')
      },
      // 展会信息
      {
        path: '/exhibitionInfo',
        name: 'exhibitionInfo',
        component: () => import('../views/exhibitionInfo.vue')
      },
      // 展会信息2
      {
        path: '/exhibitionInfo2',
        name: 'exhibitionInfo2',
        component: () => import('../views/exhibitionInfo2.vue')
      },
      // 公告列表
      {
        path: '/announcement/list',
        name: 'list',
        component: () => import('../pages/announcement/list.vue')
      },
      // 公告详情页
      {
        path: '/announcement/info',
        name: 'info',
        component: () => import('../pages/announcement/info.vue')
      },
      // 搜索结果页 (分类结果)
      {
        path: '/search',
        name: 'search',
        component: () => import('../pages/searchPage/search.vue')
      },
      // 搜索结果页2 (搜索结果)
      {
        path: '/search2',
        name: 'search2',
        component: () => import('../pages/searchPage/search2.vue')
      },
      // 发布寻源页
      {
        path: '/souurcingPublish',
        name: 'souurcingPublish',
        component: () => import('../pages/sourcing/souurcingPublish.vue')
      },

    ],
    
  },

  // 非首页tab栏页面 ------------------------------------------------

  // 企业主页 - 商品详情页
  {
    path: '/enterpriseHome',
    name: 'enterpriseHome',
    redirect: "enterpriseContent",
    component: () => import('../pages/enterprise/enterpriseHome.vue'),
    children: [
      // 主页
      {
        path: '/enterpriseHome/enterpriseContent',
        name: 'enterpriseContent',
        component: () => import('../pages/enterprise/enterpriseContent.vue'),
      },
      // 全部商品页面 
      {
        path: '/enterpriseHome/enterpriseCommodity',
        name: 'enterpriseCommodity',
        component: () => import('../pages/enterprise/enterpriseCommodity.vue'),
      },
      // 企业信息页面 
      {
        path: '/enterpriseHome/enterpriseInformation',
        name: 'enterpriseInformation',
        component: () => import('../pages/enterprise/enterpriseInformation.vue'),
      },
      // 商品详情页 
      {
        path: '/commodity/productDetails',
        name: 'productDetails',
        component: () => import('../pages/commodity/productDetails.vue'),
      },

    ]
  },

  // 我的页面
  {
    path: '/myIndex',
    name: 'myIndex',
    redirect: "myOrder",
    component: () => import('../pages/myPage/myIndex.vue'),
    children: [
      // 我的订单
      {
        path: '/myIndex/myOrder',
        name: 'myOrder',
        component: () => import('../pages/myPage/myOrder.vue'),
      },
      // 我的售后页面 
      {
        path: '/myIndex/myAfterSales',
        name: 'myAfterSales',
        component: () => import('../pages/myPage/myAfterSales.vue'),
      },
      // 申请售后页面 
      {
        path: '/myIndex/applyForAfterSales',
        name: 'applyForAfterSales',
        component: () => import('../pages/myPage/applyForAfterSales.vue'),
      },
      // 我的售后详情
      {
        path: '/myIndex/afterTheDetails',
        name: 'afterTheDetails',
        component: () => import('../pages/myPage/afterTheDetails.vue'),
      },
      // 我的优惠券
      {
        path: '/myIndex/myCoupon',
        name: 'myCoupon',
        component: () => import('../pages/myPage/myCoupon.vue'),
      },
      // 我的收货地址
      {
        path: '/myIndex/myAddress',
        name: 'myAddress',
        component: () => import('../pages/myPage/myAddress.vue'),
      },
      // 我的开票信息
      {
        path: '/myIndex/myInvoice',
        name: 'myInvoice',
        component: () => import('../pages/myPage/myInvoice.vue'),
      },
      // 我的收藏页面
      {
        path: '/myIndex/myCollect',
        name: 'myCollect',
        component: () => import('../pages/myPage/myCollect.vue'),
      },
       // 我的浏览页面
       {
        path: '/myIndex/myBrowsingHistory',
        name: 'myBrowsingHistory',
        component: () => import('../pages/myPage/myBrowsingHistory.vue'),
      },
      // 我的关注页面
      {
        path: '/myIndex/myFocusOn',
        name: 'myFocusOn',
        component: () => import('../pages/myPage/myFocusOn.vue'),
      },
      // 我的寻源页面
      {
        path: '/myIndex/mySourcing',
        name: 'mySourcing',
        component: () => import('../pages/myPage/mySourcing.vue'),
      },
      // 寻源详情页面
      {
        path: '/myIndex/mySourcingDetails',
        name: 'mySourcingDetails',
        component: () => import('../pages/myPage/mySourcingDetails.vue'),
      },
       // 寻源-报价详情页面
       {
        path: '/myIndex/mySourcingOfferDetails',
        name: 'mySourcingOfferDetails',
        component: () => import('../pages/myPage/mySourcingOfferDetails.vue'),
      },
    ]
  },
  // 我的订单详情
  {
    path: '/myIndex/orderDetails',
    name: 'orderDetails',
    component: () => import('../pages/myPage/orderDetails.vue'),
  },
  // 我的订单 发布评价
  {
    path: '/myIndex/toEvaluate',
    name: 'toEvaluate',
    component: () => import('../pages/myPage/toEvaluate.vue'),
  },
  // 帮助中心 
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: () => import('../pages/helpCenter/helpCenter.vue'),
  },
   // 我的购物车
   {
    path: '/myShoppingCart',
    name: 'myShoppingCart',
    component: () => import('../pages/myPage/myShoppingCart.vue'),
  },
   // 支付页面
   {
    path: '/payment',
    name: 'payment',
    component: () => import('../pages/payment/payment.vue'),
  },
  // 微信支付页面
  {
    path: '/payment/WeChatPayPage',
    name: 'WeChatPayPage',
    component: () => import('../pages/payment/WeChatPayPage.vue'),
  },
  // 在线聊天
  {
    path: '/onlineChat',
    name: 'WeChatPayPage',
    component: () => import('../components/onlineChat.vue'),
  },

]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  if (to.name == 'hoveContent') {
    store.commit('setCurrentPage', true)
  } else {
    store.commit('setCurrentPage', false)
  }
})


export default router
