import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import { message } from './utils/reset-message' // reset message

Vue.use(ElementUI)
Vue.prototype.$message = message

// 这个数据只会被加载一次，并且可以在任何组件中方便地使用，不需要在每个组件中都导入一次。
import AddressPlugin from './utils/address.js'
Vue.use(AddressPlugin);


router.beforeEach((to, from, next) => {
  // document.title = to.meta.title || 'CNCX商城'; // 设置默认标题，如果路由没有设置title的话
  document.title = 'CNCX商城'; // 设置默认标题，如果路由没有设置title的话
  next();
});


Vue.config.productionTip = false
Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
