<template>
  <div class="homeContent">
    <!-- 轮播图 -->
    <div class="swiper">
      <div style="width: 210px; height: 470px"></div>
      <div class="rotation">
        <div class="top">
          <swiper :options="swiperOption" @swiper="onSwiper" @slideChange="onSlideChange" style="height: 100%">
            <swiper-slide v-for="(i, index) in bannerlist" :key="index">
              <img style="width: 100%;height: 100%;object-fit: cover;" :src="i.activityImg" alt="" />
            </swiper-slide>
            <!-- <swiper-slide> <img style="width: 100%;height: 100%;object-fit: cover;" src="@/assets/banner.png"
                alt="" /></swiper-slide> -->
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
        <div class="btn">
          <div class="img cur" @click="genDuoVR">
            <img style="width: 100%;height: 100%;object-fit: none;object-position: center;border-radius: 4px;"
              src="@/assets/yyt1.png" alt="" />
          </div>
          <div class="img cur" @click="genDuoMS">
            <img style="width: 100%;height: 100%;object-fit: none;object-position: center;border-radius: 4px;"
              src="@/assets/yyt2.png" alt="" />
          </div>
          <div class="img cur" @click="genDuoYHQ">
            <img style="width: 100%;height: 100%;object-fit: none;object-position: center;border-radius: 4px;"
              src="@/assets/yyt3.png" alt="" />
          </div>
          <div class="img cur" @click="jczqClick">
            <img style="width: 100%;height: 100%;object-fit: none;object-position: center;border-radius: 4px;"
              src="@/assets/yyt4.png" alt="" />
          </div>
        </div>
      </div>
      <div class="logInfo">
        <div class="toux cur" @click="infoClick">
          <img style="border-radius: 50%;object-fit: cover;" v-if="userInfo.userImage" :src="userInfo.userImage" alt="" />
          <img style="border-radius: 50%;object-fit: cover;" v-else src="@/assets/mrtx.png" alt="" />
          <div class="text">
            <div style="color: #000000; display: flex; align-items: center">
              HI，
              <div
                style="max-width: 70px;height: 100%;display: inline-block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;cursor: pointer;">
                {{ userInfo.userName || userInfo.realName || '' }}
              </div>
              &nbsp;&nbsp;您好！
            </div>
            <div>欢迎来到CNC-X商城</div>
          </div>
        </div>
        <div class="btn" style="justify-content: center;" v-if="!userInfo.userId">
          <div class="l" style="width: 125px;height: 30px;line-height: 30px;" @click="loginClick">登陆体验完整功能</div>
          <!-- <div class="r">注册</div> -->
        </div>
        <div class="btn2" v-else>
          <div class="son" @click="GWClick">
            <div clang="num" style="color: #ff4242; font-weight: bold">{{ totalLength }}</div>
            <div class="title">购物车</div>
          </div>
          <div class="son" @click="DSHlick">
            <div clang="num" style="color: #ff4242; font-weight: bold">{{ orderListInfoDSH.total }}</div>
            <div class="title">待收货</div>
          </div>
          <div class="son" @click="DFHlick">
            <div clang="num" style="color: #ff4242; font-weight: bold">{{ orderListInfoDFH.total }}</div>
            <div class="title">待发货</div>
          </div>
          <div class="son" @click="DFKlick">
            <div clang="num" style="color: #ff4242; font-weight: bold">{{ orderListInfoDZF.total }}</div>
            <div class="title">待付款</div>
          </div>
        </div>
        <div class="mokuai">
          <div class="son" @click="scClick">
            <img src="@/assets/icon/sc.png" alt="" />
            <div class="text">宝贝收藏</div>
          </div>
          <div class="son" @click="gzClick">
            <img src="@/assets/icon/gz.png" alt="" />
            <div class="text">关注的店</div>
          </div>
          <div class="son" @click="jlClick">
            <img src="@/assets/icon/llju.png" alt="" />
            <div class="text">浏览记录</div>
          </div>
        </div>
        <div class="zhucImg" @click="genDuoYHQ">
          <img src="@/assets/hoveyh.png" alt="" />
        </div>
        <div class="gongg">
          <div class="l">网站公告</div>
          <div class="r" @click="announcement">
            <div>更多</div>
            <img src="@/assets/icon/gd.png" alt="" />
          </div>
        </div>
        <div class="gonggSon" v-for="(i, ind) in noticeList" :key="ind" @click="announInfo(i)">
          <div class="l">{{ i.typeName }}</div>
          <div class="text">{{ i.articleTitle }}</div>
        </div>
      </div>
    </div>
    <!-- 官方自营 秒杀 -->
    <div class="selfSupport_seckill">
      <div class="selfSupport">
        <div class="jianb"></div>
        <div class="head">
          <div class="l">
            <div class="title">官方自营</div>
            <img src="@/assets/icon/re.png" alt="">
          </div>
          <div class="r">
            <div> </div>
            <!-- <div class="text">满5000立减800</div>
            <div class="text">满10000包邮</div> -->
            <div class="gend" @click="zyClick">
              <div class="text">更多</div>
              <img src="@/assets/icon/gd2.png" alt="">
            </div>
          </div>
        </div>
        <div class="commodity">
          <el-skeleton v-if="officialProprietaryRecommendedList.length == 0" animated :throttle="500">
            <template slot="template">
              <div
                style="width: 570px;height: 268px;display: flex;justify-content: space-between;padding:  20px;box-sizing: border-box;margin-left: -20px;">
                <div v-for="(s, index) in 3" :key="index">
                  <el-skeleton-item variant="image" style="width: 145px; height: 145px;" />
                  <div style="padding: 14px;">
                    <el-skeleton-item variant="p" style="width: 50%;margin-bottom: 10px;" />
                    <div style="display: flex; align-items: center; justify-items: space-between;">
                      <el-skeleton-item variant="text" style="margin-right: 16px;" />
                      <el-skeleton-item variant="text" style="width: 30%;" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-skeleton>
          <div class="son" v-for="(i, index) in officialProprietaryRecommendedList" :key="index"
            @click="commodityProductDetails(i.spuId)">
            <div class="img">
              <img :src="i.mainImage" alt="">
            </div>
            <div class="name">{{ i.title }}</div>
            <div class="jiage">
              <div class="jg">￥{{ i.originalPrice }}</div>
              <div class="text">销量 {{ i.sales }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="seckill">
        <div class="jianb"></div>
        <div class="head">
          <div class="l">
            <div class="title">正在秒杀</div>
            <img src="@/assets/icon/ms.png" alt="">
          </div>
          <div class="r">
            <div> </div>
            <!-- <div class="text">倒计时</div>
            <div class="djs">
              <div>{{ formatNumber(hours) }}</div> <span>:</span>
              <div>{{ formatNumber(minutes) }}</div> <span>:</span>
              <div>{{ formatNumber(seconds) }}</div>
            </div> -->
            <div class="gend" @click="MSClick">
              <div class="text">更多</div>
              <img src="@/assets/icon/gd2.png" alt="">
            </div>
          </div>
        </div>
        <div class="commodity">
          <el-skeleton v-if="isKillRecommendedList.length == 0" animated :throttle="500">
            <template slot="template">
              <div
                style="width: 570px;height: 268px;display: flex;justify-content: space-between;padding:  20px;box-sizing: border-box;margin-left: -20px;">
                <div v-for="(s, index) in 3" :key="index">
                  <el-skeleton-item variant="image" style="width: 145px; height: 145px;" />
                  <div style="padding: 14px;">
                    <el-skeleton-item variant="p" style="width: 50%;margin-bottom: 10px;" />
                    <div style="display: flex; align-items: center; justify-items: space-between;">
                      <el-skeleton-item variant="text" style="margin-right: 16px;" />
                      <el-skeleton-item variant="text" style="width: 30%;" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-skeleton>
          <div class="son" v-for="(i, index) in isKillRecommendedList" :key="index" @click="commodityProductDetailsMS(i)">
            <div class="img">
              <img :src="i.picUrl" alt="">
            </div>
            <img class="zjbq" src="@/assets/icon/zjbq.png" alt="">
            <div class="name">{{ i.mallSku.specification }}</div>
            <div class="jiage">
              <div class="jg">￥{{ i.seckillPrice }}</div>
              <div class="text">销量 {{ i.seckillNum }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- VR展会 精品商城 优惠券 -->
    <div class="exhibition_boutique_shopping">
      <div class="exhibition">
        <div class="heas">
          <div class="title">VR展会</div>
          <div class="r" @click="genDuoVR">
            <div class="text">更多展会</div>
            <img src="@/assets/icon/gd.png" alt="">
          </div>
        </div>
        <div class="swiper">
          <el-skeleton animated :throttle="500" v-if="VrList.length == 0">
            <template slot="template">
              <el-skeleton-item variant="image" style="width: 380px; height: 180px;" />
              <el-skeleton-item variant="text" style="width: 280px; height: 20px;margin-top: 10px;" />
            </template>
          </el-skeleton>
          <swiper :options="swiperOption2"
            style="height: 100%;padding-bottom: 65px;cursor: pointer;border-radius: 4px;overflow: hidden;">
            <swiper-slide v-for="(i, index) in VrList" :key="index">
              <div class="img" style="width: 100%;height: 180px;" @click="VRClick(i)">
                <img style="width: 100%;height: 100%;object-fit: cover;" :src="i.picture" alt="" />
              </div>
              <div style="width: 100%;text-align: center;font-size: 14px;color: #333;margin-top: 5px;">{{ i.title }}</div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <div class="boutique">
        <div class="heas">
          <div class="title">精品商城</div>
          <div class="r" @click="genDuoStore">
            <div class="text">更多商城</div>
            <img src="@/assets/icon/gd.png" alt="">
          </div>
        </div>
        <div class="swiper">
          <el-skeleton animated :throttle="500" v-if="shoppingList.length == 0">
            <template slot="template">
              <div style="width: 380px;height: 210px;">
                <el-skeleton-item variant="text" style="width: 380px; height: 100px;margin-top: 10px;" />
                <el-skeleton-item variant="text" style="width: 380px; height: 100px;margin-top: 10px;" />
              </div>
            </template>
          </el-skeleton>

          <swiper :options="swiperOption3" style="height: 100%;cursor: pointer;border-radius: 4px;overflow: hidden;">
            <swiper-slide v-for="(item, index) in shoppingList" :key="index"
              style="height: 210px;display: flex;flex-direction:column;justify-content: space-between;">
              <div @click="qyClick(idem)" v-for="(idem, inde) in item" :key="inde"
                style="width: 360px;height: 100px;border-radius: 8px; background-color: #f5f5f5;margin: 0 auto;display: flex;align-items: center;padding: 0 15px;box-sizing: border-box;">
                <div class="img"
                  style="width: 80px ;height: 80px;margin-right: 13px;overflow: hidden;border-radius: 8px;background-color: #fff;">
                  <img style="width: 100%;height: 100%;object-fit: contain;" :src="idem.storeLog" alt="">
                </div>
                <div
                  style="width: 230px;height: 90px;display: flex; flex-direction:column;justify-content: space-around;padding: 5px 0;box-sizing: border-box;">
                  <div style="width: 100%;height: 28px;display: flex;align-items: center;">
                    <div style="font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{
                      idem.storeName || idem.companyName }}</div>
                  </div>
                  <div
                    style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: #666;font-size: 12px;">
                    {{ idem.storeMajor }}</div>
                  <div style="font-size: 12px;color: #999;display: flex;margin-top: 8px;">
                    <!-- <div>综合评分5.0</div>
                    <div style="margin-left: 10px;">12万人关注</div> -->
                    <div
                      style="width: 28px;height: 16px;margin: 0 3px; border-radius: 25px;background-color: #FF4242;text-align: center;line-height: 16px;color: #fff;font-size: 12px;">
                      诚{{ idem.storeGrade }}</div>
                    <div
                      style="width: 45px;height: 16px;border-radius: 25px;background-color: #fff;border: 1px solid #FF4242; text-align: center;line-height: 16px;color: #FF4242;font-size: 12px;">
                      自营店</div>
                  </div>
                </div>
              </div>
            </swiper-slide>


            <div class="swiper-pagination" style="opacity: 0;" slot="pagination"></div>
            <div class="swiper-button-prev" style="width: 20px;" slot="button-prev"></div>
            <div class="swiper-button-next" style="width: 20px;" slot="button-next"></div>

          </swiper>
        </div>
      </div>
      <div class="shopping">
        <div class="heas">
          <div class="title">优惠券</div>
          <div class="r" @click="genDuoYHQ">
            <div class="text">更多好券</div>
            <img src="@/assets/icon/gd.png" alt="">
          </div>
        </div>
        <el-skeleton animated :throttle="500" v-if="listOfCoupons.length == 0">
          <template slot="template">
            <div style="width: 320px;height: 210px;">
              <el-skeleton-item variant="text" style="width: 300px; height: 70px;margin-top: 10px;" />
              <el-skeleton-item variant="text" style="width: 300px; height: 70px;margin-top: 10px;" />
              <el-skeleton-item variant="text" style="width: 300px; height: 70px;margin-top: 10px;" />
            </div>
          </template>
        </el-skeleton>

        <div class="yhq" v-for="(i, index) in listOfCoupons" :key="index">
          <div class="copper" v-if="i.type == '1' || i.type == '4'">￥<span class="text">{{ i.reduceAmount ||
            i.fixationAmount }}</span></div>
          <div class="copper" v-if="i.type == '2'"><span class="text">{{ i.discount }} <span
                style="font-size: 16px;">折</span> </span></div>
          <div class="copper" v-if="i.type == '3'">￥<span class="text">{{ i.fixationAmount }}</span></div>
          <div class="r" @click="lingQuYHQ(i)">
            <div class="r_t">满{{ i.premiseAmount }}可用</div>
            <div class="r_b">
              <span v-if="i.type == '1'">减满券</span>
              <span v-if="i.type == '2'">折扣券</span>
              <span v-if="i.type == '3'">固定券</span>
              <span v-if="i.type == '4'">卡密券</span>
              <span v-if="i.suitType == '1'"> 全平台可使用</span>
              <span v-if="i.suitType == '2'"> 指定商品使用</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 数控必抢 -->
    <div class="mustSnatch">
      <div class="heas">
        <div class="title">数控必抢</div>
        <div class="r" @click="genDuoCNC">
          <div class="text">更多品牌</div>
          <img src="@/assets/icon/gd.png" alt="">
        </div>
      </div>
      <div class="content">
        <div class="son" v-for="(i, index) in CNCMustGrabInfo" :key="index" @click="commClick(i)">
          <div class="lei">{{ i.brandName }}</div>
          <img :src="i.img.split(',')[0]" alt="">
          <div class="r" style="margin-left: 10px;">
            <div class="name">{{ i.title }}</div>
            <div class="tab">{{ i.exemptionFromPostage == 'BY' ? '包邮' : i.exemptionFromPostage == 'BBY' ? '不包邮' : '满额包邮'
            }}</div>
            <div class="progress">
              <!-- <div style="width: 120px;">
                <el-progress :percentage="percentage" :stroke-width="12" color="#FB3541" define-back-color="#FFE3CA"
                  :show-text="false"></el-progress>
              </div> -->
              <span>已抢{{ i.sales }}件</span>
            </div>
            <div class="jiage">
              <div class="nub">￥<span>{{ i.price || '面议' }}</span></div>
              <div class="yuanj">￥{{ i.price2 }} </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 热门品牌 -->
    <div class="great_demand">
      <div class="heas">
        <div class="title">热门品牌</div>
        <div class="pp">
          <!-- :class="[i.act ? 'act' : '']" -->
          <div v-for="(i, index) in brandList.slice(0, 7)" :class="[i.act ? 'act' : '']" :key="index" @click="ppClick(i)">
            {{
              i.brandName }}</div>
        </div>
        <div class="r" @click="genDBreant()">
          <div class="text">更多品牌</div>
          <img src="@/assets/icon/gd.png" alt="">
        </div>
      </div>
      <div class="content">
        <div class="l">
          <swiper :options="swiperOption4" @swiper="onSwiper" @slideChange="onSlideChange" style="height: 100%">
            <swiper-slide><img style="width: 100%" src="@/assets/rmpp.png" alt="" /></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="r">
          <div class="son" v-for="(i, index) in brandList" :key="index" @click="ppClick(i)">
            <div class="img">
              <img :src="i.logo" alt="">
            </div>
            <div class="hover">
              {{ i.brandName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 为你推荐 -->
    <div style="width: 100%;padding: 36px 0 28px 0;display: flex;align-items: center;justify-content: center;">
      <img style="width: 160px;height: 32px;" src="@/assets/wntj.png" alt="">
    </div>
    <div class="tuijian" ref="wrapper">
      <div class="son" v-for="(i, index) in recommendShoolList" :key="index" @click="commodityProductDetails(i.spuId)">
        <div class="img">
          <img :src="i.mainImage">
        </div>
        <div class="name">
          {{ i.title }}
        </div>
        <div class="jiage">
          <div class="l" v-if="i.spuType == 'discuss'">面议</div>
          <div class="l" v-else>￥{{ i.price }}</div>
          <div v-if="i.type == '2'"
            style="width: 40px;height: 20px;border-radius: 4px;text-align: center;line-height: 20px;font-size: 12px;color: #fff;background-color: #FF4242;margin-right: 5px;">
            定金</div>
          <div class="r" v-if="i.sales > 10000">销量 1万+</div>
          <div class="r" v-if="i.sales < 10000">销量 {{ i.sales }}</div>
        </div>
      </div>
    </div>

    <!-- 固定按钮 -->
    <!-- <div class="fixed">

      <div class="son son2">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/ewm.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/ewmA.png" alt="">
        </div>
        <div class="text">手机购</div>
        <div class="ewm">
          <div class="img">
            <img src="https://image.cnc-x.cn/brand/20220923102224.png" alt="">
          </div>
          <div class="text">扫一扫下载app</div>
        </div>
      </div>
      <div class="son">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/gwc.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/gwcA.png" alt="">
        </div>
        <div class="text">购物车</div>
      </div>
      <div class="son">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/kf.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/kfA.png" alt="">
        </div>
        <div class="text">客服</div>
      </div>
      <div class="son">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/fk.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/fkA.png" alt="">
        </div>
        <div class="text">反馈</div>
      </div>
      <transition name="fade">
        <div class="son back-to-top" @click="scrollToTop" v-if="showButton">
          <div class="img">
            <img class="img1" src="@/assets/hoveIcon/hd.png" alt="">
            <img class="img2" src="@/assets/hoveIcon/hdA.png" alt="">
          </div>
          <div class="text">顶部</div>
        </div>
      </transition>
    </div> -->

  </div>
</template>

<script>
import { getUserInfo } from "@/utils/api/myApi/index"
import { getBannerList, getRecommendShoolList, postClaimCoupons, getNoticeList, gettNoticeType } from "@/utils/api/homeApi/index"
export default {
  name: "hoveContent",
  data() {
    return {
      // 数控必抢数据
      CNCMustGrabInfo: [],
      // 用户信息
      userInfo: {},
      // 购物车列表
      shoppingCartList: [],
      // 购物车长度
      totalLength: 0,
      // 订单列表
      orderListInfoDFH: {},
      orderListInfoDSH: {},
      orderListInfoDZF: {},
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
          dynamicBullets: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: false,
      },
      swiperOption2: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: false,
      },
      swiperOption3: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置轮播可循环
        loop: true,
      },
      swiperOption4: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
          dynamicBullets: true,
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: false,
      },
      // 倒计时
      countDownDate: new Date("2023-05-07 15:18:40").getTime(), // 目标日期
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timer: null, // 定时器
      // 进度条
      percentage: 60,
      // 是否显示回顶按钮
      showButton: false,
      // 轮播图首页
      bannerlist: [],
      // 官方自营推荐列表
      officialProprietaryRecommendedList: [],
      // 秒杀推荐列表
      isKillRecommendedList: [],
      // 展会推荐列表
      VrList: [],
      // 精品商城列表
      shoppingList: [],
      // 优惠券列表
      listOfCoupons: [],
      // 热门品牌列表
      brandList: [],
      // 为你推荐
      recommendPaging: {
        size: 20,
        current: 1,
        total: 0
      },
      recommendShoolList: [],
      // 公告列表
      noticeList: []
    };
  },
  created() {
    // 更新倒计时
    // this.updateCountdown();
    // this.timer = setInterval(this.updateCountdown, 1000); // 每秒更新一次
  },
  mounted() {
    // 拿vuex里的用户信息
    // this.$store.commit("getUserInfoData")
    this.getUserInfoData()
    // 拿vuex里的购物车信息
    this.$store.commit("getAShoppingCartList")

    // // 拿vuex里的订单信息
    // if (this.userInfo.userId) {
    //   this.$store.commit("getOrderListInfo", {
    //     current: 1,
    //     size: 5,
    //     type: 'DFH',
    //   })
    //   this.$store.commit("getOrderListInfo", {
    //     current: 1,
    //     size: 5,
    //     type: 'DSH',
    //   })
    //   this.$store.commit("getOrderListInfo", {
    //     current: 1,
    //     size: 5,
    //     type: 'DZF',
    //   })
    // }
    this.$store.commit('getBrand', { size: 12, current: 1 })
    window.addEventListener("scroll", this.handleScroll);
    this.getBanner()
    this.getrecommendShool()
    this.getNoticeList()
    // 拿全局状态管理的值 
    this.officialProprietaryRecommendedList = this.$store.state.officialProprietaryRecommendedList.slice(0, 3)
    this.isKillRecommendedList = this.$store.state.isKillRecommendedList.slice(0, 3)
    this.VrList = this.$store.state.VrList.slice(0, 3)
    this.shoppingList = this.sliceArray(this.$store.state.shoppingList.slice(0, 6))
    this.listOfCoupons = this.$store.state.listOfCoupons.slice(0, 3)
    this.brandList = this.$store.state.brandList.slice(0, 12)
    this.CNCMustGrabInfo = this.$store.state.CNCMustGrabInfo
    this.noticeList = this.$store.state.noticeList
  },
  watch: {
    '$store.state.officialProprietaryRecommendedList'(newVal, oldVal) {
      this.officialProprietaryRecommendedList = this.$store.state.officialProprietaryRecommendedList.slice(0, 3)
    },
    '$store.state.noticeList'(newVal, oldVal) {
      this.noticeList = this.$store.state.noticeList
    },
    '$store.state.CNCMustGrabInfo'(newVal, oldVal) {
      this.CNCMustGrabInfo = this.$store.state.CNCMustGrabInfo
    },
    '$store.state.isKillRecommendedList'(newVal, oldVal) {
      this.isKillRecommendedList = this.$store.state.isKillRecommendedList.slice(0, 3)
    },
    '$store.state.VrList'(newVal, oldVal) {
      this.VrList = this.$store.state.VrList.slice(0, 3)
    },
    '$store.state.shoppingList'(newVal, oldVal) {
      this.shoppingList = this.sliceArray(this.$store.state.shoppingList.slice(0, 6))
    },
    '$store.state.listOfCoupons'(newVal, oldVal) {
      this.listOfCoupons = this.$store.state.listOfCoupons.slice(0, 3)
    },
    '$store.state.brandList'(newVal, oldVal) {
      this.brandList = this.$store.state.brandList.slice(0, 12)
    },

    '$store.state.shoppingCartList'(newVal, oldVal) {
      this.shoppingCartList = this.$store.state.shoppingCartList
      console.log(this.shoppingCartList, '购物车')
      this.totalLength = this.shoppingCartList.reduce((acc, obj) => {
        return acc + obj.mallOrderItemVos.length;
      }, 0);
    },
    '$store.state.orderListInfoDFH'(newVal, oldVal) {
      if (this.userInfo.userId) {
        this.orderListInfoDFH = this.$store.state.orderListInfoDFH
      }
    },
    '$store.state.orderListInfoDSH'(newVal, oldVal) {
      if (this.userInfo.userId) {
        this.orderListInfoDSH = this.$store.state.orderListInfoDSH
      }
    },
    '$store.state.orderListInfoDZF'(newVal, oldVal) {
      if (this.userInfo.userId) {
        this.orderListInfoDZF = this.$store.state.orderListInfoDZF
      }
    },

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    jczqClick() {
      window.open(`#/search?id=010101&typeName=立式加工中心`, '_blank');
    },
    // 获取用户信息
    async getUserInfoData(state, value) {
      const res = await getUserInfo()
      if (res.statusCode == 8201) {
        this.userInfo = res.data
        // 拿vuex里的订单信息
        if (res.data.userId) {
          this.$store.commit("getOrderListInfo", {
            current: 1,
            size: 5,
            type: 'DFH',
          })
          this.$store.commit("getOrderListInfo", {
            current: 1,
            size: 5,
            type: 'DSH',
          })
          this.$store.commit("getOrderListInfo", {
            current: 1,
            size: 5,
            type: 'DZF',
          })
        }
      }
    },
    // 跳转自营
    zyClick() {
      this.scrollToTop()
      window.open(`#/enterpriseHome/enterpriseContent?ids=396`, '_blank');
    },
    // 跳转秒杀
    MSClick() {
      this.scrollToTop()
      this.$router.push('/limitedTimeFlashKill?actNum=9')
    },
    // 跳转公告详情页
    announInfo(i) {
      this.$router.push(`/announcement/info?data=${JSON.stringify(i)}`)
    },
    // 公告列表
    async getNoticeList() {
      const res = await getNoticeList({
        current: 1,
        size: 4,
      })
      if (res.statusCode == 8201) {
        this.noticeList = res.data.records
        let ids = res.data.records.map(obj => obj.categoryId).join(",")
        const rej = await gettNoticeType(ids)
        this.noticeList.map(o => {
          rej.data.map(p => {
            if (o.categoryId == p.id) {
              this.$set(o, 'typeName', p.name)
            }
          })
        })
      }
    },
    // 跳转个人信息
    infoClick() {
      if (!this.userInfo.userId) return
      this.$router.push('/myIndex/myOrder?actMyNum=0&modifyType=home')
    },
    // 登录
    loginClick() {
      this.$router.push('/login')
    },
    // 进入展会
    VRClick(i) {
      this.scrollToTop()
      this.$router.push(`/exhibitionInfo?zhId=${i.zhId}&actNum=7`)
    },
    // 进入秒杀
    commodityProductDetailsMS(i) {
      window.open(`#/commodity/productDetails?ids=${i.seckillHallInfo.seckillHallInfoId}&type=MS&typeTime=${i.typeTime}`, '_blank');
    },
    // 操作swiper数据
    sliceArray(arr) {
      const result = [];
      for (let i = 0; i < arr.length; i += 2) {
        result.push(arr.slice(i, i + 2));
      }
      if (arr.length % 2 === 1) {
        result.push([arr[arr.length - 1]]);
      }
      return result;
    },
    // 首页轮播图
    async getBanner() {
      const res = await getBannerList('BANNER')
      if (res.statusCode == 8201) return this.bannerlist = res.data
    },
    // 为你推荐商品列表
    async getrecommendShool() {
      const res = await getRecommendShoolList(this.recommendPaging)
      if (res.statusCode == 8201) return this.recommendShoolList = [...this.recommendShoolList, ...res.data.records]
    },
    // 跳转商品详情页
    commodityProductDetails(ids) {
      // this.$store.commit('getCommodity',ids)
      window.open(`#/commodity/productDetails?ids=${ids}`, '_blank');
    },
    // 跳转商品页
    commClick(i) {
      window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
    },
    // 跳转到数控必抢页面
    genDuoCNC() {
      this.scrollToTop()
      this.$router.push('/CNCMustGrab?actNum=5')
    },
    // 跳转到VR页面
    genDuoVR() {
      this.scrollToTop()
      this.$router.push('/moreExhibitions?actNum=7')
    },
    // 跳转秒杀
    genDuoMS() {
      this.scrollToTop()
      this.$router.push('/limitedTimeFlashKill?actNum=9')
    },
    // 跳转到精品商城页面
    genDuoStore() {
      this.scrollToTop()
      this.$router.push('/boutiqueMall?actNum=1')
    },
    // 跳转到优惠券页面
    genDuoYHQ() {
      this.scrollToTop()
      this.$router.push('voucherCollectionCenter?actNum=4')
    },
    onSwiper() { },
    onSlideChange() { },
    a() {
      console.log(1);
    },
    // 返回顶部
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showButton = window.scrollY > 300;
      const wrapper = this.$refs.wrapper
      const isBottom = window.scrollY - wrapper.scrollHeight
      if (isBottom > 1200) {
        this.recommendPaging.current++
        this.getrecommendShool()
      }
    },
    // 倒计时
    updateCountdown() {
      var now = new Date().getTime();
      var distance = this.countDownDate - now;
      if (distance <= 0) {
        clearInterval(this.timer); // 停止计时器
        return;
      }
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    },
    formatNumber(num) {
      return num < 10 ? "0" + num : num;
    },
    // 品牌点击
    ppClick(i) {
      // this.demandList.map(t => t.act = false)
      // this.demandList[index].act = true
      window.open(`#/search2?keyWords=${i.brandName}&type=brand`, '_blank');
    },
    // 领取优惠券
    async lingQuYHQ(val) {
      const res = await postClaimCoupons({
        id: val.id,
        storeId: val.storeId
      })
      if (res.statusCode == 8201) {
        this.$message.success('领取成功');
      } else if (res.statusCode == 7001) {
        this.$message.error(res.message);
      }
    },
    // 跳转公告
    announcement() {
      this.scrollToTop()
      this.$router.push("/announcement/list")
    },
    spClick() {
      this.scrollToTop()
      window.open('#/commodity/productDetails', '_blank');
    },
    // 点击跳转店铺
    qyClick(idem) {
      this.scrollToTop()
      window.open(`#/enterpriseHome/enterpriseContent?ids=${idem.storeId}`, '_blank');
    },
    // 跳转我的收藏
    scClick() {
      this.scrollToTop()
      window.open('#/myIndex/myCollect?actMyNum=5', '_blank');
    },
    // 跳转购物车
    GWClick() {
      this.scrollToTop()
      window.open('#/myShoppingCart', '_blank');
    },
    // 待收货
    DSHlick() {
      this.scrollToTop()
      window.open('#/myIndex/myOrder?actMyNum=0&activeName=3', '_blank');
    },
    // 待发货
    DFHlick() {
      this.scrollToTop()
      window.open('#/myIndex/myOrder?actMyNum=0&activeName=2', '_blank');
    },
    // 待付款
    DFKlick() {
      this.scrollToTop()
      window.open('#/myIndex/myOrder?actMyNum=0&activeName=1', '_blank');
    },
    // 跳转我的关注
    gzClick() {
      this.scrollToTop()
      window.open('#/myIndex/myFocusOn?actMyNum=6', '_blank');
    },
    // 跳转浏览记录
    jlClick() {
      this.scrollToTop()
      window.open('#/myIndex/myBrowsingHistory?actMyNum=7', '_blank');
    },
    // 更多品牌
    genDBreant() {
      this.scrollToTop()
      this.$router.push("/brandCenter?actNum=2")
    }
  },
};
</script>

<style lang="less" scoped>
.homeContent {
  width: 1200px;
  height: auto;
  padding-bottom: 70px;
  margin: 11px auto;
  box-sizing: border-box;

  // 轮播图板块
  .swiper {
    width: 100%;
    height: 470px;
    display: flex;
    // margin-left: 10px;

    >.rotation {
      width: 720px;
      height: 470px;
      margin-left: 10px;

      >.top {
        width: 720px;
        height: 340px;
      }

      >.btn {
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        >.img {
          width: 172px;
          height: 120px;
          display: flex;
          align-items: center;
        }
      }
    }

    >.logInfo {
      width: 240px;
      height: 470px;
      margin-left: 10px;
      border-radius: 4px;
      background-color: #fff;
      padding: 32px 20px 20px 20px;
      position: relative;
      box-sizing: border-box;

      >.toux {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0 10px;
        box-sizing: border-box;

        >img {
          width: 54px;
          height: 54px;
        }

        >.text {
          font-size: 12px;
          color: #666;
        }
      }

      >.btn2 {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 22px;

        >.son {
          width: 40px;
          height: 35px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          >.num {
            font-size: 14px;
          }

          >.title {
            font-size: 12px;
            color: #666666;
            transition: all 0.3s;
            margin-top: 3px;
          }
        }

        >.son:hover {
          >.title {
            font-size: 12px;
            color: #ff4242;
            transition: all 0.3s;
          }
        }
      }

      >.btn {
        width: 100%;
        height: auto;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 22px;

        >div {
          width: 72px;
          height: 25px;
          border-radius: 20px;
          text-align: center;
          line-height: 27px;
          font-size: 12px;
          cursor: pointer;
          transition: all 0.3s;
        }

        >div:hover {
          opacity: 0.8;
          transition: all 0.3s;
        }

        >.l {
          background-color: #ff4242;
          color: #fff;
        }

        >.r {
          background-color: #ffe0e0;
          color: #ff4242;
          border: 1px solid #ff4242;
        }
      }

      >.mokuai {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 22px;

        >.son {
          width: 58px;
          height: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          color: #666;
          transition: all 0.3s;
          cursor: pointer;

          >img {
            width: 24px;
            height: 24px;
            transition: all 0.3s;
          }

          >.text {
            font-size: 12px;
            margin-top: 10px;
            transition: all 0.3s;
          }
        }

        >.son:hover {
          filter: saturate(200%);
          color: #ff4242 !important;
          transition: all 0.3s;

          >img {
            transition: all 0.3s;
          }

          >.text {
            font-size: 12px;
            transition: all 0.3s;
          }
        }
      }

      >.zhucImg {
        width: 207px;
        height: 65px;
        margin: 0 auto;
        margin-top: 20px;

        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }

      >.gongg {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 22px;

        >.l {
          font-size: 12px;
          color: #000;
          padding-bottom: 2px;
          box-sizing: border-box;
          border-bottom: 2px solid #ff4242;
        }

        >.r {
          height: 20px;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #666;
          cursor: pointer;

          >img {
            width: 13px;
            height: 13px;
            margin-left: 5px;
          }
        }
      }

      .gonggSon {
        width: 205px;
        height: 16px;
        display: flex;
        align-items: center;
        margin-top: 12px;
        cursor: pointer;

        >.l {
          width: 24px;
          height: 100%;
          text-align: center;
          line-height: 16px;
          background-color: #ffeaea;
          font-size: 12px;
          color: #ff4f4f;
        }

        >.text {
          width: 177px;
          height: 100%;
          margin-left: 4px;
          font-size: 12px;
          color: #333;
          transition: all 0.3s;
          white-space: nowrap;
          /* 禁止文本换行 */
          overflow: hidden;
          /* 隐藏文本超出部分 */
          text-overflow: ellipsis;
          /* 超出一行时显示省略号 */
        }

        .text:hover {
          color: #ff4242;
          transition: all 0.3s;
        }
      }
    }
  }

  // 官方自营 秒杀
  >.selfSupport_seckill {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    >.selfSupport {
      width: 590px;
      height: 320px;
      position: relative;
      padding: 14px 11px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;

      >.jianb {
        width: 100%;
        height: 150px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(to bottom, #FFD5A5, #fff);
      }

      >.head {
        width: 100%;
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
        z-index: 9;
        position: absolute;

        >.l {
          width: auto;
          height: 28px;
          display: flex;
          align-items: center;

          >.title {
            font-size: 20px;
            font-weight: bold;
            background: linear-gradient(to bottom, #EE3D3D, #D20000);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          >img {
            width: 22px;
            height: 22px;
            margin-left: 10px;
            margin-top: 2px;
          }
        }

        >.r {
          width: 230px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 20px;

          >.text {
            font-size: 12px;
            color: #ff4242;
            margin-right: 15px;
          }

          >.gend {
            width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            >.text {
              font-size: 12px;
              color: #C6864B;
            }

            >img {
              width: 13px;
              height: 13px;
              margin-left: 3px;
            }
          }
        }
      }

      >.commodity {
        width: 570px;
        height: 268px;
        background-color: #FFFCF9;
        border-radius: 8px;
        position: absolute;
        z-index: 9;
        margin-top: 42px;
        padding: 10px 20px;
        box-sizing: border-box;
        display: flex;
        // flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        gap: 20px;

        >.son {
          width: 165px;
          height: 244px;
          background-color: #fff;
          border-radius: 4px;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #fff;
          transition: all .3s;
          cursor: pointer;

          >.img {
            width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;

            >img {
              width: 145px;
              height: 145px;
              object-fit: cover;
              transition: all .3s;
            }
          }

          >.img:hover {
            >img {
              transform: scale(1.2);
              transition: all .3s;
            }
          }


          >.name {
            font-size: 14px;
            color: #000;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-top: 8px;
            transition: all .3s;
          }

          >.jiage {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 5px;

            >.jg {
              font-size: 16px;
              color: #ff4242;
            }

            >.text {
              font-size: 12px;
              color: #666666;
              margin-left: 10px;
            }
          }
        }

        >.son:hover {
          border: 1px solid #FF4242;
          transition: all .3s;

          >.name {
            color: #ff4242;
            transition: all .3s;
          }
        }
      }
    }

    >.seckill {
      width: 590px;
      height: 320px;
      position: relative;
      padding: 14px 11px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;

      >.jianb {
        width: 100%;
        height: 150px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(to bottom, #FEC0C0, #FFC8C8);
      }

      >.head {
        width: 100%;
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
        z-index: 9;
        position: absolute;

        >.l {
          width: auto;
          height: 28px;
          display: flex;
          align-items: center;

          >.title {
            font-size: 20px;
            font-weight: bold;
            background: linear-gradient(to bottom, #EE3D3D, #D20000);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          >img {
            width: 22px;
            height: 22px;
            margin-left: 10px;
            margin-top: 2px;
          }
        }

        // >.r {
        //   width: 150px;
        //   height: 28px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-between;
        //   margin-right: 20px;

        //   >.text {
        //     color: #FF3C3C;
        //     font-size: 14px;
        //   }

        //   >.djs {
        //     display: flex;
        //     align-items: center;

        //     >div {
        //       width: 24px;
        //       height: 24px;
        //       border-radius: 4px;
        //       text-align: center;
        //       line-height: 24px;
        //       font-size: 14px;
        //       color: #fff;
        //       background-color: #FF3C3C;
        //     }

        //     >span {
        //       color: #FF3C3C;
        //       margin: 0 5px;
        //     }
        //   }
        // }
        >.r {
          width: 230px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 20px;

          >.text {
            font-size: 12px;
            color: #ff4242;
            margin-right: 15px;
          }

          >.gend {
            width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            >.text {
              font-size: 12px;
              color: #C6864B;
            }

            >img {
              width: 13px;
              height: 13px;
              margin-left: 3px;
            }
          }
        }
      }

      >.commodity {
        width: 570px;
        height: 268px;
        background-color: #FFFCF9;
        border-radius: 8px;
        position: absolute;
        z-index: 9;
        margin-top: 42px;
        padding: 10px 20px;
        box-sizing: border-box;
        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 20px;
        // justify-content: space-between;


        >.son {
          width: 165px;
          height: 244px;
          background-color: #fff;
          border-radius: 4px;
          padding: 10px;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          border: 1px solid #fff;
          transition: all .3s;

          >.img {
            width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;

            >img {
              width: 145px;
              height: 145px;
              object-fit: cover;
              transition: all .3s;
            }
          }

          >.img:hover {
            >img {
              transform: scale(1.2);
              transition: all .3s;
            }
          }

          >.zjbq {
            width: 35px;
            height: 32px;
            position: absolute;
            top: 0;
          }


          >.name {
            font-size: 14px;
            color: #000;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-top: 8px;
            transition: all .3s;
          }

          >.jiage {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 5px;

            >.jg {
              font-size: 16px;
              color: #ff4242;
            }

            >.text {
              font-size: 12px;
              color: #666666;
              margin-left: 10px;
            }
          }
        }

        >.son:hover {
          border: 1px solid #FF4242;
          transition: all .3s;

          >.name {
            color: #ff4242;
            transition: all .3s;
          }
        }
      }
    }

  }

  // VR展会 精品商城 优惠券
  .exhibition_boutique_shopping {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    >.exhibition {
      width: 415px;
      height: 300px;
      background-color: #fff;
      border-radius: 8px;
      padding: 0 20px;
      box-sizing: border-box;

      >.heas {
        width: 100%;
        height: 35px;
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.title {
          font-size: 20px;
          font-weight: bold;
          color: #333;
        }

        >.r {
          color: #666;
          font-size: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;

          >img {
            margin-top: 2px;
            margin-left: 4px;
          }
        }
      }

      >.swiper {
        width: 380px;
        height: 174px;
        margin-top: 10px;
      }
    }

    >.boutique {
      width: 415px;
      height: 300px;
      background-color: #fff;
      border-radius: 8px;
      padding: 0 20px;
      box-sizing: border-box;

      >.heas {
        width: 100%;
        height: 35px;
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.title {
          font-size: 20px;
          font-weight: bold;
          color: #333;
        }

        >.r {
          color: #666;
          font-size: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;

          >img {
            margin-top: 2px;
            margin-left: 4px;
          }
        }
      }

      >.swiper {
        width: 380px;
        height: 210px;
        margin-top: 10px;
      }
    }

    >.shopping {
      width: 320px;
      height: 300px;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;

      >.heas {
        width: 100%;
        height: 35px;
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.title {
          font-size: 20px;
          font-weight: bold;
          color: #333;
        }

        >.r {
          color: #666;
          font-size: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;

          >img {
            margin-top: 2px;
            margin-left: 4px;
          }
        }
      }

      >.yhq {
        width: 320px;
        height: 75px;
        margin-top: 5px;
        background: url(../assets/yhqbg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;

        >.copper {
          width: 45%;
          color: #ff4242;
          text-align: center;
          line-height: 70px;

          >span {
            font-size: 30px;
          }
        }

        >.r {
          width: 55%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: -10px;

          >.r_t {
            color: #FF4242;
            font-size: 18px;
          }

          >.r_b {
            color: #E08939;
            font-size: 12px;
          }
        }
      }
    }

  }

  // 数控必抢
  >.mustSnatch {
    width: 1200px;
    height: 230px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 20px;
    padding: 0px 20px;
    box-sizing: border-box;

    >.heas {
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 18px;
      box-sizing: border-box;

      >.title {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }

      >.r {
        color: #666;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;

        >img {
          margin-top: 2px;
          margin-left: 4px;
        }
      }
    }

    >.content {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      >.son {
        width: 370px;
        height: 150px;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-content: center;
        position: relative;
        cursor: pointer;

        >.lei {
          position: absolute;
          top: 0;
          right: 0;
          width: 56px;
          height: 18px;
          border-radius: 0px 4px 0px 9px;
          background-color: #CEF0FF;
          border: 1px solid #80C9FF;
          text-align: center;
          line-height: 18px;
          color: #387FEA;
          font-size: 12px;
        }

        >img {
          width: 130px;
          height: 130px;
        }

        >.r {
          width: 220px;
          height: 130px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-top: 5px;

          >.name {
            width: 220px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          >.tab {
            width: 59px;
            height: 18px;
            border-radius: 10px;
            text-align: center;
            line-height: 18px;
            color: #E26D00;
            background-color: #FFF7D9;
            border: 1px solid #FFBE82;
            font-size: 12px;
          }

          >.progress {
            width: 100%;
            display: flex;
            align-items: center;

            >span {
              font-size: 12px;
              color: #999;
              margin-left: 5px;
            }
          }

          >.jiage {
            width: 100%;
            display: flex;
            align-items: center;

            >.nub {
              width: 78px;
              height: 36px;
              text-align: center;
              line-height: 25px;
              color: #fff;
              background: url(../assets/icon/skbqjgbg.png);
              background-size: 100% 100%;
              font-size: 12px;

              >span {
                font-size: 14px;
                font-weight: bold;
              }
            }

            >.yuanj {
              font-size: 12px;
              color: #999;
              text-decoration: line-through;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  // 热门品牌
  >.great_demand {
    width: 1200px;
    height: 313px;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 20px;

    >.heas {
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 18px;
      box-sizing: border-box;

      >.title {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }

      >.pp {
        width: 950px;
        display: flex;
        align-items: center;
        padding-top: 10px;

        >div {
          font-size: 12px;
          color: #666;
          margin-right: 20px;
          cursor: pointer;
          transition: all .3s;
        }

        >div:hover {
          color: #FF4242;
          transition: all .3s;
        }
      }

      >.r {
        color: #666;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;

        >img {
          margin-top: 2px;
          margin-left: 4px;
        }
      }
    }

    >.content {
      width: 100%;
      margin-top: 20px;
      height: 238px;
      display: flex;

      >.l {
        width: 314px;
        height: 230px;
        cursor: pointer;
      }

      >.r {
        width: 840px;
        height: 238px;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #E0E0E0;

        >.son {
          width: 208px;
          height: 78px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          position: relative;
          transition: all .3s;
          cursor: pointer;
          border-right: 1px solid #E0E0E0;
          border-bottom: 1px solid #E0E0E0;

          >.img {
            width: 128px;
            height: 48px;

            >img {
              width: 100%;
              height: 100%;
            }
          }

          >.hover {
            width: 208px;
            height: 78px;
            background-color: rgba(0, 0, 0, .4);
            position: absolute;
            top: 78px;
            transition: all .3s;
            text-align: center;
            line-height: 78px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
          }
        }

        >.son:hover {
          >.hover {
            top: 0;
            transition: all .3s;
          }
        }
      }
    }
  }

  // 为你推荐
  >.tuijian {
    width: 1200px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >.son {
      width: 220px;
      height: 280px;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 10px;
      border: 1px solid #fff;
      box-sizing: border-box;
      transition: all .3s;
      cursor: pointer;

      >.img {
        width: 200px;
        height: 200px;
        overflow: hidden;

        >img {
          width: 100%;
          height: 100%;
          transition: all .3s;
        }
      }

      >.img:hover {
        >img {
          transform: scale(1.2);
          transition: all .3s;
        }
      }

      >.name {
        font-size: 14px;
        color: #000;
        width: 100%;
        margin-top: 10px;
        white-space: nowrap;
        transition: all .3s;
        /* 禁止换行 */
        overflow: hidden;
        /* 超出部分隐藏 */
        text-overflow: ellipsis;
        /* 超出部分显示省略号 */
      }

      >.jiage {
        width: 100%;
        height: 25px;
        margin-top: 5px;
        display: flex;
        align-items: flex-end;

        >.l {
          font-size: 16px;
          font-weight: bold;
          color: #FF4242;
          margin-right: 6px;
        }

        >.r {
          font-size: 12px;
          color: #666;
          margin-bottom: 2px;
        }
      }
    }

    >.son:hover {
      border: 1px solid #FFA5A5;
      transition: all .3s;

      >.name {
        color: #FF4242;
        transition: all .3s;
      }
    }
  }

  // 固定按钮
  >.fixed {
    width: 54px;
    height: 270px;
    position: fixed;
    top: 600px;
    right: 0;
    // z-index: 999;


    >.son {
      width: 54px;
      height: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-bottom: 2px;
      cursor: pointer;

      >.ewm {
        width: 97px;
        height: 120px;
        background-color: #fff;
        padding: 8px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: -100px;
        opacity: 0;
        transition: all .3s;

        >.img {
          width: 80px;
          height: 80px;

          >img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        >.text {
          font-size: 10px;
          color: #333;
          margin-top: 8px;
        }
      }

      >.img {
        width: 20px;
        height: 20px;
        position: relative;

        >img {
          position: absolute;
          top: 0;
          left: 0;
        }

        >.img1 {
          opacity: 1;
          transition: all .3s;
        }

        >.img2 {
          opacity: 0;
          transition: all .3s;
        }
      }

      >.text {
        font-size: 12px;
        color: #333;
        margin-top: 3px;
        transition: all .3s;
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s ease-in-out;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }



    >.son2:hover {
      >.ewm {
        opacity: 1;
      }
    }

    >.son:hover {
      .img1 {
        opacity: 0;
        transition: all .3s;
      }

      .img2 {
        opacity: 1;
        transition: all .3s;
      }

      >div {
        color: #FF4242;
        transition: all .3s;
      }
    }
  }

}



// 右
.swiper-button-next {
  background-color: rgba(0, 0, 0, 0.3);
  margin-right: -10px;
}

.swiper-button-next:after,
.swiper-button-next:after {
  font-size: 22px;
  color: #fff;
}

// 左
.swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.3);
  margin-left: -10px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 22px;
  color: #fff;
}

/deep/ .swiper-pagination-bullet-active {
  background-color: #ff4242 !important;
}

// 
.act {
  color: #FF4242 !important;
  font-size: 13px;
}
</style>