<template>
  <div id="app">
    <transition name="slide-left">
      <router-view />
    </transition>

    <!-- 固定按钮 -->
    <div class="fixed">

      <div class="son son2">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/ewm.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/ewmA.png" alt="">
        </div>
        <div class="text">手机购</div>
        <div class="ewm">
          <div class="img">
            <img src="@/assets/xzapp.png" alt="">
          </div>
          <div class="text">扫一扫下载app</div>
        </div>
      </div>
      <div class="son" @click="GWClick">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/gwc.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/gwcA.png" alt="">
        </div>
        <div class="text">购物车</div>
      </div>
      <!-- <div class="son">
        <div class="img">
          <img class="img1" src="@/assets/hoveIcon/kf.png" alt="">
          <img class="img2" src="@/assets/hoveIcon/kfA.png" alt="">
        </div>
        <div class="text">客服</div>
      </div> -->
      <transition name="fade">
        <div class="son back-to-top" @click="scrollToTop" v-if="showButton">
          <div class="img">
            <img class="img1" src="@/assets/hoveIcon/hd.png" alt="">
            <img class="img2" src="@/assets/hoveIcon/hdA.png" alt="">
          </div>
          <div class="text">顶部</div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VueNativeSock from 'vue-native-websocket';
export default {
  data() {
    return {
      // 是否显示回顶按钮
      showButton: false,
      socket: null,
      totalLength: 0
    }
  },
  created() {
    // this.establishVueNativeSock()
    // this.startConnectionCheck()

    // setInterval(() => {
    //   this.checkConnectionStatus();
    // }, 3000);






    // 首页推荐
    this.$store.commit('getOfficialList', { size: 3, current: 1 })
    this.$store.commit('getRecommendedList', { size: 3, current: 1 })
    this.$store.commit('getVrList', { size: 3, current: 1 })
    this.$store.commit('getShoppingList', { size: 10, current: 1 })
    this.$store.commit('getOfCoupons', { size: 3, current: 1 })
    this.$store.commit('getBrand', { size: 12, current: 1 })
    // 公告列表
    this.$store.commit('getNoticeListClick', { size: 4, current: 1 })
    // 数控必抢
    this.$store.commit('getCNCMustGrabInfo')
  },
  mounted() {
    window.addEventListener('touchmove', this.handleTouchMove, { passive: true });
    window.addEventListener("scroll", this.handleScroll);

    // this.$socket.addEventListener('message', this.onMessage);
    // this.$store.commit('getAShoppingCartList')
    // this.totalLength = this.$store.state.shoppingCartList.reduce((acc, obj) => {
    //   return acc + obj.mallOrderItemVos.length;
    // }, 0);

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  watch: {
    // '$store.state.socketSendValue'(newVal, oldVal) {
    //   var data = {
    //     tUid: "255208",
    //     fUid: "1636312775900925952",
    //     mt: 100,
    //     msg: '测试场测试',
    //     userInfo: 'true'
    //   };
    //   console.log(data)
    //   try {
    //     this.socket.send(data);
    //   } catch (error) {
    //     console.error('发送消息时出错:', error);
    //   }
    // },

    '$route.path': {
      handler(newVal, oldVal) {
        this.$store.commit('getAShoppingCartList')
        // let shoppingCartList = this.$store.state.shoppingCartList
        // this.totalLength = shoppingCartList.reduce((acc, obj) => {
        //   return acc + obj.mallOrderItemVos.length;
        // }, 0);
      },
      immediate: true 
    }
  },
  methods: {

    // 建立VueNativeSock
    establishVueNativeSock() {
      console.log("建立链接")
      // Vue.use(VueNativeSock, `wss://im.cnc-x.cn/server/${localStorage.getItem('token')}`, {
      //   reconnection: true,
      //   reconnectionAttempts: 5,
      //   reconnectionDelay: 3000
      // });
      const socket = new WebSocket(`wss://im.cnc-x.cn/server/${localStorage.getItem('token')}`); // 使用你的WebSocket URL
      this.socket = socket
      socket.onopen = () => {
        console.log('WebSocket连接已建立');
      };

      socket.onmessage = (event) => {
        this.handleMessage(event.data);
      };

      socket.onclose = () => {
        console.log('WebSocket连接已关闭');
        // this.reconnectWebSocket()
      };

      socket.onerror = (error) => {
        console.error('WebSocket错误:', error);
      };
    },

    // 检测链接状态
    startConnectionCheck() {
      setInterval(() => {
        if (this.socket.readyState !== WebSocket.OPEN) {
          console.log('WebSocket连接已断开，重新连接...');
          this.reconnectWebSocket();
        } else {
          console.log("链接正常")
        }
      }, 3000);
    },
    reconnectWebSocket() {
      // 延迟1秒后重新连接WebSocket
      if (this.socket) {
        this.socket.close();
      }
      setTimeout(() => {
        console.log("链接断开，不进行重连")
        // this.establishVueNativeSock();
      }, 1000);
    },

    // 处理接收到的WebSocket消息
    handleMessage(data) {
      console.log('收到消息', JSON.parse(data))
      this.$store.commit('socketValue', JSON.parse(data))
    },

    // // 监听状态
    // checkConnectionStatus() {

    //   this.$socket.onopen = () => {
    //     console.log('WebSocket连接已建立');
    //     // 在这里执行连接建立后的操作
    //     // this.handleConnectionOpen();
    //   };

    //   this.$socket.onclose = () => {
    //     console.log('WebSocket连接已关闭');
    //     // 在这里执行连接关闭后的操作
    //     // this.handleConnectionClose();
    //   };

    //   this.$socket.onerror = () => {
    //     console.log('WebSocket连接发生错误');
    //     // 在这里执行连接错误后的操作
    //     // this.handleConnectionError();
    //   };

    //   this.$socket.onmessage = (event) => {
    //     const message = event.data;
    //     // console.log('Received message:', JSON.parse(message));
    //     // 在这里处理接收到的WebSocket消息
    //     // this.handleMessage(JSON.parse(message));
    //   };

    //   if (!this.$socket || this.$socket.readyState === WebSocket.CLOSED) {
    //     console.log('WebSocket连接已断开，尝试重新连接...');
    //     this.reconnect();
    //   }else{
    //     console.log('WebSocket连接正常');
    //   }
    //   // console.log(WebSocket.CLOSED,this.$socket)
    // },




    // 跳转购物车
    GWClick() {
      this.scrollToTop()
      if (this.$route.path == "/myShoppingCart") return
      // console.log(this.$route.path == "/myShoppingCart") 
      window.open('#/myShoppingCart', '_blank');
    },
    // 返回顶部
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showButton = window.scrollY > 300;
    },
  }
}
</script>
<style lang="less">
#id {
  min-width: 1200px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// 固定按钮
.fixed {
  width: 54px;
  height: 270px;
  position: fixed;
  top: 600px;
  right: 0;
  // z-index: 999;


  >.son {
    width: 54px;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin-bottom: 2px;
    cursor: pointer;

    >.ewm {
      width: 97px;
      height: 120px;
      background-color: #fff;
      padding: 8px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: -100px;
      opacity: 0;
      transition: all .3s;

      >.img {
        width: 80px;
        height: 80px;

        >img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      >.text {
        font-size: 10px;
        color: #333;
        margin-top: 8px;
      }
    }

    >.img {
      width: 20px;
      height: 20px;
      position: relative;

      >img {
        position: absolute;
        top: 0;
        left: 0;
      }

      >.img1 {
        opacity: 1;
        transition: all .3s;
      }

      >.img2 {
        opacity: 0;
        transition: all .3s;
      }
    }

    >.text {
      font-size: 12px;
      color: #333;
      margin-top: 3px;
      transition: all .3s;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }



  >.son2:hover {
    >.ewm {
      opacity: 1;
    }
  }

  >.son:hover {
    .img1 {
      opacity: 0;
      transition: all .3s;
    }

    .img2 {
      opacity: 1;
      transition: all .3s;
    }

    >div {
      color: #FF4242;
      transition: all .3s;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
}

/* .slide-left-enter-active,
.slide-left-leave-active {
  opacity: 0;
  transition: all 1s ease;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(-10%);
  opacity: 1;
} */

.cur {
  cursor: pointer;
}
</style>
