import { render, staticRenderFns } from "./largeHead.vue?vue&type=template&id=222daa00&scoped=true&"
import script from "./largeHead.vue?vue&type=script&lang=js&"
export * from "./largeHead.vue?vue&type=script&lang=js&"
import style0 from "./largeHead.vue?vue&type=style&index=0&id=222daa00&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "222daa00",
  null
  
)

export default component.exports