import request from '@/utils/request'
import qs from 'qs'

// 我得接口


// 获取我得收藏
export function getForIHaveToCollect(data) {
    return request({
        url: `/mall/mall-user-collect/subList?currentPage=${data.currentPage}&pageSize=${data.pageSize}&type=${data.type}`,
        method: 'get',
    })
}

// 获取我关注的店铺
export function getAttentionStore(data) {
    return request({
        url: `/mall/mall-user-info/attentionStore?currentPage=${data.currentPage}&pageSize=${data.pageSize}`,
        method: 'get',
    })
}

// 添加收货地址
export function postAddress(data) {
    return request({
        url: `/user/user-address/addx`,
        method: 'post',
        data
    })
}

// 获取收货地址
export function getAddress(data) {
    return request({
        url: `/user/user-address/listx?start=${data.start}&length=${data.length}&channel=mall`,
        method: 'get',
    })
}

// 删除收货地址
export function postAddressDelete(ids) {
    return request({
        url: `/user/user-address/del/${ids}`,
        method: 'post',
    })
}

// 获取购物车列表
export function getAShoppingCart(ids) {
    return request({
        url: `/mall/mall-cart/findCart`,
        method: 'get',
    })
}

// 删除购物车商品 
export function deleteShoppingCartOfGoods(storeCartId) {
    return request({
        url: `/mall/mall-cart/del`,
        method: 'delete',
        data: {
            storeCartId
        }
    })
}

// 密码登录
export function getPasswordLogin(data) {
    return request({
        url: `/user/login?telephone=${data.phone}&password=${data.password}&channel=mall`,
        method: 'get'
    })
}

// 验证码登录
export function postPhoneCodeLogin(data) {
    return request({
        url: `user/LoginByPcode`,
        method: 'post',
        data: {
            channel: "mall",
            pcode: data.phoneCode,
            telephone: data.phone,
        }
    })
}

// 获取验证码
export function getPhoneCode(phone) {
    return request({
        url: `/user/selectByPhone?telephone=${phone}`,
        method: 'get'
    })
}

// 修改密码
export function postChangePassword(data) {
    return request({
        url: `/user/user/resetPasswordByCode`,
        method: 'post',
        data:{
            code:data.phoneCode,
            password:data.passwordNew,
            phone:data.phone
        }
    })
}

// 获取用户信息
export function getUserInfo() {
    return request({
        url: `/user/user/userInfo`,
        method: 'get'
    })
}


// 我的订单列表
export function getOrderList(data) {
    return request({
        url: `/mall/mall-order-info/page?current=${data.current}&size=${data.size}&oStatus=${data.type}&type=user&descs=create_time`,
        method: 'get'
    })
}


// 商家入驻选择主营业务
export function getMainBusiness() {
    return request({
        url: `/user/goods-category/listByCategory`,
        method: 'get'
    })
}

// 商家入驻选择品牌
export function getMainSelectBrand(data) {
    return request({
        url: `/user/goods-brand/list?start=${data.start}&length=${data.length}`,
        method: 'get'
    })
}

// 商家入驻提交接口
export function getMerchantSettlementSubmit(formSubmite) {
    return request({
        url: `/mall/mall-store/enter`,
        method: 'post',
        data:formSubmite
    })
}
// 查询入驻店铺详细信息
export function getStoreInfoQuery() { 
    return request({
        url: `/mall/mall-store/storeStatus`,
        method: 'get',
    })
}

// 查询我的浏览记录
export function getBrowsingHistory(data) { 
    return request({
        url: `/collector/mall_browse_history/queryList?showhandle=false&currentPage=${data.currentPage}&pageSize=${data.pageSize}&total=0`,
        method: 'get',
    })
}

// 查询我的优惠券
export function getmyCoupon(data) { 
    let url = ''
    if(data.spuId){
        url =  `/mall/couponuser/page2?status=${data.status}&current=${data.current}&size=${data.size}&descs=create_time&storeId=${data.storeId || ''}&spuIds=${data.spuId || ''}`
    }else {
        url =  `/mall/couponuser/page2?status=${data.status}&current=${data.current}&size=${data.size}&descs=create_time&storeId=${data.storeId || ''}`
    }
    return request({
        url: url,
        method: 'get',
    })
}

// 获取寻源列表
export function getSourcingList(paging) { 
    return request({
        url: `/mall/mall-purchase-demand/subList?currentPage=${paging.page}&pageSize=${paging.sizi}&demandStatus=${paging.type}`,
        method: 'get',
    })
}

// 获取寻源商品信息
export function getSourcingInfo(demandId) { 
    return request({
        url: `/mall/mall-purchase-demand/get?demandId=${demandId}`,
        method: 'get',
    })
}

// 根据分类id查询分类信息 
export function getTypeIdInfo(id) { 
    return request({
        url: `/user/goods-category/getLists?typeIds=${id}`,
        method: 'get',
    })
}

// 取消寻源
export function postCancelSourcing(id) { 
    return request({
        url: `/mall/mall-purchase-demand/save`,
        method: 'post',
        data:{
            demandId:id,
            type:'edit'
        }
    })
}


// 获取寻源下单订单号
export function postSourcingOrder(data) { 
    return request({
        url: `/mall/mall-order-info/searchOrder`,
        method: 'post',
        data
    })
}

// 根据单号获取支付参数
export function postSourcingPayment(data) { 
    return request({
        url: `/user/alipay/repairPay`,
        method: 'post',
        data
    })
}

// 根据单号查看是否支付成功
export function getPaymentStatus(data) { 
    return request({
        url: `/user/alipay/VR/wxQueryOrder`,
        method: 'post',
        data
    })
}

// 查询运费
export function getFreightAll(data) { 
    return request({
        url: `/mall/mall-order-info/freight?${qs.stringify(data)}`,
        method: 'get',
        // params:data
    })
}

// 秒杀订单
export function getFreightOrder (parmes) { 
    return request({
        url: `/mall/mall-order-info`,
        method: 'post',
        data:parmes
    })
}

// 获取我的订单列表
export function getMyOrderList (data) { 
    return request({
        url: `/mall/mall-order-info/page?current=${data.current}&size=${data.size}&oStatus=${data.oStatus}&type=user&descs=create_time`,
        method: 'get',
    })
}

// 删除订单
export function getOrderIdInfoDel (oId) { 
    return request({
        url: `/mall/mall-order-info/remove/${oId}`,
        method: 'delete',
    })
}

// 根据订单号查询订单详情
export function getOrderIdInfo (oId) { 
    return request({
        url: `/mall/mall-order-info/${oId}`,
        method: 'get',
    })
}

// 查询所有快递类型
export function getDeliveryType () { 
    return request({
        url: `/mall/enum/list?name=ShipCodeType`,
        method: 'get',
    })
}

// 根据订单号修改订单地址 确认收货 取消订单
export function getOrderSelectAddreee(data) { 
    return request({
        url: `/mall/mall-order-info/cancelOrder`,
        method: 'post',
        data
    })
}

// 根据订单号查询快递单号
export function getViewLogisticsClick (oId) { 
    return request({
        url: `/mall/kdniao/getInfo`,
        method: 'post',
        data:{
            oId
        }
    })
}

// 评价
export function postEvaluate(data) { 
    return request({
        url: `/mall/mall-evaluate/save`,
        method: 'post',
        data:{
            mallevaluate:data
        }
    })
}

// 申请退款
export function postRequestRefund(data) { 
    return request({
        url: `/mall/mall-order-returns`,
        method: 'post',
        data
    })
}

// 获取售后退款列表
export function getAfterSalesList(data) { 
    return request({
        url: `/mall/mall-order-returns/page?type=user&descs=create_time&current=${data.current}&size=${data.size}`,
        method: 'get',
    })
}

// 根据售后订单id查询售后详情
export function getAfterSalesInfo(id) { 
    return request({
        url: `/mall/mall-order-returns/${id}`,
        method: 'get',
    })
}

// 根据售后订单id查询售后协商历史
export function getAfterSalesNegotiationHistory(id) { 
    return request({
        url: `/mall/mall-order-returns-record/list?returnsId=${id}`,
        method: 'get',
    })
}

// 获取取消售后的原因
export function getCancellationAfterSalesReason(data) { 
    return request({
        url: `/user/data-dictionary/getResource?resource=mall&value=${data.value}`,
        method: 'get',
    })
}

// 取消售后
export function getCancellationAfterSales(data) { 
    return request({
        url: `/mall/mall-order-returns`,
        method: 'put',
        data
    })
}

// 修改头像
export function getModifyAvatar(data) { 
    return request({
        url: `/api/user/addx`,
        method: 'post',
        data
    })
}

// 修改密码 重设密码
export function getResetPassword(data) { 
    return request({
        url: `/user/user-info/password?telephone=${data.telephone}&password=${data.password}`,
        method: 'get',
        data
    })
}

// 保存浏览记录
export function getSaveBrowsingHistory(data) { 
    return request({
        url: `/collector/mall_browse_history/save`,
        method: 'post',
        data
    })
}

// 获取汇款账户信息
export function getStoreAccount() { 
    return request({
        url: `/mall/mallbankaccounts/0`,
        method: 'get'
    })
}

// 上传汇款凭证
export function getSubmiteHK(data) { 
    return request({
        url: `/mall/mall-order-info/updateBatchById`,
        method: 'put',
        data
    })
}

// 添加发票信息
export function postAddInvoice(data) { 
    return request({
        url: `/mall/invoice`,
        method: 'post',
        data
    })
}

// 获取发票信息列表
export function getInvoiceList(data) { 
    return request({
        url: `/mall/invoice/page?current=${data.current}&size=${data.size}`,
        method: 'get'
    })
}

// 删除发票信息
export function delInvoice(id) { 
    return request({
        url: `/mall/invoice/${id}`,
        method: 'delete'
    })
}

// 编辑发票信息
export function editInvoice(data) { 
    return request({
        url: `/mall/invoice`,
        method: 'put',
        data
    })
}


// 申请开票
export function putApplyInvoicing(data) { 
    return request({
        url: `/mall/mall-order-info/updateById`,
        method: 'put',
        data
    })
}

// 确认收货
export function postAffirmOrder(data) { 
    return request({
        url: `/mall/mall-order-info/cancelOrder`,
        method: 'post',
        data
    })
}

// 在线聊天  -------------

// 左侧聊天列表
export function getMessageList(data) { 
    return request({
        url: `/user/messageList?type=user&pageSize=${data.size}&currentPage=${data.page}`,
        method: 'get'
    })
}

// 右侧商品数据
export function getMessageComm(data) { 
    return request({
        url: `/mall/mall-store/selectBySpu?title=${data.title}&type=${data.type}&storeCategoryId=${data.storeCategoryId}&storeId=${data.storeId}&currentPage=${data.page}&pageSize=${data.size}`,
        method: 'get'
    })
}
// 右侧订单数据
export function getMessageOrder(data) { 
    return request({
        url: `/mall/mall-order-info/page?title=${data.title}&type=${data.type}&storeCategoryId=${data.storeCategoryId}&storeId=${data.storeId}&currentPage=${data.page}&pageSize=${data.size}`,
        method: 'get'
    })
}
